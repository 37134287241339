import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Footer, NavigationBar, FullPageLoader } from "components";
import { QUERY } from "graphql-actions";
import { accountActions } from "../../../store/actions";
import ProfileInfor from "./profileInfo";
import Security from "./security";
import MyProperties from "./myProperties";
import Requests from "./requests";
import MyDetails from "./myDetails";
import { BiHeart, BiHome, BiBell, BiShield, BiUser } from "react-icons/bi";
import SavedProperties from "./savedProperties";
import { useLocation, useNavigate } from "react-router-dom";
import { UserType } from "utils";

const tabMenu = [
  { title: "My Details", icon: BiUser, tab: "details" },
  { title: "Security", icon: BiShield, tab: "security" },
  { title: "My Properties", icon: BiHome, tab: "properties" },
  { title: "Requests", icon: BiBell, tab: "requests" },
  { title: "Saved Properties", icon: BiHeart, tab: "favourites" },
];

const Account = ({ account, accountActions }) => {
  const [activeTab, setActiveTab] = useState(0);
  const match = useLocation();
  const nav = useNavigate();
  const isTenant = account.type === UserType.TENANT;
  const { loading } = useQuery(QUERY.USER.GET_CURRENT_USER, {
    fetchPolicy: "network-only",
    onCompleted: async ({ getCurrentUser }) => {
      await accountActions.addAccountDetails(getCurrentUser || {});
    },
  });

  useEffect(() => {
    let param = "";
    if (match.search) {
      param = match.search.replace("?tab=", "");
      const tab = tabMenu.findIndex((x) => param === x.tab);
      if (tab !== -1) {
        if (!isTenant && tabMenu[tab].tab === "favourites") return null;
        if (isTenant && tabMenu[tab].tab === "properties") return null;
        return setActiveTab(tab);
      }
      return nav("/account");
    }
  }, [isTenant, match, nav]);

  const renderActiveTab = () => {
    switch (activeTab) {
      case 0:
        return <MyDetails account={account} />;
      case 1:
        return <Security account={account} />;
      case 2:
        return <MyProperties account={account} />;
      case 3:
        return <Requests account={account} />;
      case 4:
        return <SavedProperties />;
      default:
        return (
          <div>
            {" "}
            <h1>My Details</h1>
          </div>
        );
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="account-main-container">
        <div
          style={{
            maxWidth: 1000,
            margin: "0px auto",
            minHeight: "28vh",
            alignItems: "center",
            width: "100%",
          }}
        >
          {loading ? (
            <FullPageLoader loading />
          ) : (
            <>
              <ProfileInfor account={account} />
              <div
                style={{
                  display: "flex",
                  marginBottom: 40,
                  padding: "0px 20px",
                }}
              >
                {tabMenu.map((X, index) => {
                  const isActive = activeTab === index;
                  if (!isTenant && X.title === "Saved Properties") return null;
                  if (isTenant && X.title === "My Properties") return null;
                  return (
                    <div
                      key={index}
                      onClick={() => setActiveTab(index)}
                      style={{
                        cursor: "pointer",
                        padding: "10px 15px",
                        backgroundColor: !isActive ? "#F5F5F5" : "#7CB73F",
                        borderRadius: 5,
                        fontSize: 12,
                        fontWeight: isActive ? 700 : 600,
                        marginRight: 20,
                      }}
                    >
                      <span
                        className={`account-tab-title`}
                        style={{ color: !isActive ? "#212121" : "#fff" }}
                      >
                        {X.title}
                      </span>
                      <X.icon
                        className="account-tab-icon"
                        size={21}
                        color={!isActive ? "#212121" : "#fff"}
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{ padding: "0px 20px" }}> {renderActiveTab()} </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
