import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Oval } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { QUERY } from "graphql-actions";
import { clsx } from "clsx";
import RequestCard from "../../../../components/requestCard";
import { Button } from "components";

const Requests = ({ account }) => {
  const [activeFilter, setActiveFilter] = useState("ALL");
  const [filteredData, setFilteredData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;
  const { loading, data } = useQuery(QUERY.REQUESTS.GET_USER_REQUESTS);

  useEffect(() => {
    if (!loading) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data?.getUserRequests?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data?.getUserRequests?.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, loading]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData?.length;
    setCurrentPage(event?.selected);
    setItemOffset(newOffset);
    // window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (currentPage !== 0 && !currentItems?.length) {
      const newOffset =
        ((currentPage - 1) * itemsPerPage) % filteredData?.length;
      setCurrentPage(currentPage - 1);
      setItemOffset(newOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItems]);

  const onFilter = (value) => {
    setActiveFilter(value);
    let requests = data?.getUserRequests;
    if (value !== "ALL") {
      requests = data?.getUserRequests?.filter((x) => x.status === value);
    }
    setFilteredData(requests);
    setCurrentPage(0);
    setCurrentItems(requests?.slice(0, itemsPerPage));
    setPageCount(Math.ceil(requests?.length / itemsPerPage));
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          color: "#212121",
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        Requests
      </div>
      <div
        style={{
          width: "100%",
          fontSize: 13,
          fontWeight: 600,
          color: "#6a6a6a",
        }}
      >
        Filter by:
      </div>
      <div style={{ display: "flex", marginBottom: 40, flexWrap: "wrap" }}>
        {["All", "Accepted", "Rejected", "Pending"].map((filter, index) => {
          const isActive = activeFilter.toLowerCase() === filter.toLowerCase();
          return (
            <Button
              key={index}
              className={clsx("dark small mr-[10px] mt-2", {
                "!bg-[#F5F5F5] !text-[#212121] !border-[#F5F5F5]": !isActive,
              })}
              title={filter}
              onClick={() => onFilter(filter.toUpperCase())}
            />
          );
        })}
      </div>
      <div>
        {!loading && (
          <div
            class={clsx("flex flex-row flex-wrap", {
              "mb-10": currentItems?.length,
            })}
          >
            {currentItems?.map((request, index) => (
              <RequestCard key={index} request={request} account={account} />
            ))}
          </div>
        )}
        {!currentItems?.length && !loading && (
          <div
            style={{
              fontSize: 16,
              color: "#6a6a6a",
              fontWeight: "500",
              marginBottom: 20,
            }}
          >
            No requests yet.
          </div>
        )}
        <Oval
          height={30}
          width={30}
          color="#7CB73F"
          wrapperStyle={{}}
          wrapperClass=""
          visible={loading}
          ariaLabel="oval-loading"
          secondaryColor="#7CB73F"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      </div>
      <div>
        {!loading && (
          <ReactPaginate
            renderOnZeroPageCount={null}
            previousLabel={
              <BiChevronLeft
                size={20}
                style={{ marginTop: -20, marginLeft: 5 }}
              />
            }
            nextLabel={
              <BiChevronRight
                size={20}
                style={{ marginTop: -20, marginLeft: 5 }}
              />
            }
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageClick}
            forcePage={currentPage}
            containerClassName={"pagination"}
            previousLinkClassName={"previous_page"}
            nextLinkClassName={"next_page"}
            activeClassName={"active_number"}
            pageClassName={"inactive_number"}
            marginPagesDisplayed={0}
            pageRangeDisplayed={5}
          />
        )}
      </div>
    </div>
  );
};

export default Requests;
