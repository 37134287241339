import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import Uploader from "../../../../components/input/upload";
import { useMutation } from "@apollo/client";
import { MUTATION } from "graphql-actions";
import { accountActions } from "../../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Alert, Input } from "components";
import Form from "../../../../components/Form";
import { useFormik } from "formik";
import { useValidationSchema } from "./hook/use-validation-schema";
import Button from "../../../../components/button";
import {
  StyledImagePreview,
  StyledImagePreviewContainer,
  StyledImageRemoveButton,
  StyledInputRowContainer,
} from "./myDetails.styles";

const MyDetails = ({ account, accountActions }) => {
  const [error, setError] = useState(null);
  const [updateProfile, { data, loading }] = useMutation(
    MUTATION.USER.UPDATE_PROFILE,
    {
      onCompleted: (data) => {
        accountActions.updateAccountDetails(data.updateProfile);
        formik.resetForm();
      },
      onError: (err) => setError(err),
    }
  );

  const onUpdate = (values) => {
    setError(null);
    return updateProfile({
      variables: {
        profilePicture: values?.profilePicture,
        input: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          phoneNumber: values?.phoneNumber,
          whatsappNumber: values?.whatsappNumber,
        },
      },
    });
  };

  const validationSchema = useValidationSchema();
  const formik = useFormik({
    validationSchema,
    onSubmit: (values) => onUpdate(values),
    enableReinitialize: true,
    initialValues: {
      ...account,
      profilePicture: null,
    },
  });

  console.log("data?.updateProfile", data?.updateProfile, error?.message);
  return (
    <Form formik={formik}>
      <div style={{ width: 500, maxWidth: "100%" }}>
        <div
          style={{
            fontSize: 24,
            color: "#212121",
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          My Details
        </div>
        <Alert
          error={error}
          isShow={data?.updateProfile}
          message={"Your profile updated successfully."}
        />
        <div style={{ position: "relative", marginTop: 20 }}>
          <div>
            <div
              style={{
                marginBottom: 10,
                fontSize: 12,
                color: "#6a6a6a",
                fontWeight: 500,
              }}
            >
              Profile picture
            </div>
            {formik.values.profilePicture && (
              <StyledImagePreviewContainer>
                <StyledImageRemoveButton
                  onClick={() => formik.setFieldValue("profilePicture", null)}
                >
                  <IoClose
                    size={20}
                    onClick={null}
                    style={{ margin: "auto" }}
                  />
                </StyledImageRemoveButton>
                <StyledImagePreview src={formik.values.profilePicture} />
              </StyledImagePreviewContainer>
            )}
            <Uploader name="profilePicture" />
          </div>
        </div>
        <StyledInputRowContainer>
          <div style={{ width: "48%" }}>
            <Input.TextField label={"First name"} name="firstName" />
          </div>
          <div style={{ width: "48%" }}>
            <Input.TextField label={"Last name"} name="lastName" />
          </div>
        </StyledInputRowContainer>
        <div style={{ position: "relative" }}>
          <Input.TextField label={"Phone number"} name="phoneNumber" disabled />
        </div>
        <div style={{ position: "relative" }}>
          <Input.TextField label={"Whatsapp number"} name="whatsappNumber" />
        </div>
        <div style={{ position: "relative" }}>
          <Input.TextField
            label={"Email address"}
            name="email"
            placeholder="N/A"
          />
        </div>
        <div>
          <Button
            className="primary"
            disabled={!formik.dirty}
            loading={loading || formik.isSubmitting}
            type="submit"
            title="Save Changes"
          />
        </div>
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails);
