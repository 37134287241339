import styled from "styled-components";

export const MainFormComponentStyle = styled.div`
  width: 100%;
  border-radius: 5;
  padding: 0px 20px;
  box-sizing: border-box;
  min-width: 650px;
`;

export const RowComponentStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;
