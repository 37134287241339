import { useField } from "formik";
import React from "react";
import { StyledInputLabel } from "../textField/textField.styles";

const Select = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <StyledInputLabel>{label}</StyledInputLabel>
      <select as="select" className="custom-input select" {...props} {...field}>
        <option disabled value="">
          Select Options
        </option>
        {options.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? (
        <div className="input-error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Select;
