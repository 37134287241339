import moment from "moment";
import * as yup from "yup";

export const useValidationSchema = (activeStep, data) => {
  const requiredMessage = "Please fill in the required field.";
  const selectInputRequiredMessage = "Please choose an option.";

  return yup.object({
    rent: yup.number().required(requiredMessage).nullable(),
    deposit: yup.number().nullable().required(requiredMessage),
    meterbox: yup.boolean().required(requiredMessage).nullable(),
    parking: yup.boolean().required(requiredMessage).nullable(),
    pets: yup.boolean().required(requiredMessage).nullable(),
    kids: yup.boolean().required(requiredMessage).nullable(),
    rentalType: yup.string().required(selectInputRequiredMessage).nullable(),
    bathroomType: yup.string().required(selectInputRequiredMessage).nullable(),
    availability: yup
      .date()
      .required(requiredMessage)
      .nullable()
      .test("availability", "Date should be today or in the future", (date) => {
        const oldDate = moment(data?.availability).format("YYYY-MM-DD");
        const today = moment(new Date()).format("YYYY-MM-DD");
        const userDate = moment(date).format("YYYY-MM-DD");
        if (data?.availability) {
          if (!moment(oldDate).isSame(userDate)) {
            return moment(userDate).isSameOrAfter(today);
          }
          return moment(oldDate).isSame(userDate);
        }
        return moment(userDate).isSameOrAfter(today);
      }),
    description: yup.string().required(requiredMessage).nullable(),
    address:
      activeStep !== 1
        ? yup.object()
        : yup.object().shape({
            placeId: yup.string(),
            fullAddress: yup
              .string()
              .required("Please provide full address")
              .nullable(),
            geo: yup.object().shape({
              lat: yup.string(),
              lng: yup.string(),
            }),
          }),

    images:
      activeStep > 1
        ? yup
            .array()
            .min(1, requiredMessage)
            .required(requiredMessage)
            .nullable()
        : yup.array(),
  });
};
