import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { MUTATION } from "graphql-actions";
import { Link, useParams } from "react-router-dom";
import { Alert } from "components";

const PasswordChange = ({ onSuccess, token }) => {
  const params = useParams();
  const [newPassword, setNewPassword] = useState(null);
  const [error, setError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);

  const [resetPassword, { loading }] = useMutation(
    MUTATION.AUTH.RESET_PASSWORD,
    {
      onCompleted: () => {
        onSuccess();
        setError(null);
      },
      onError: (error) => setError(error),
    }
  );

  const PasswordIcon = newPasswordShow ? BsEye : BsEyeSlash;
  const ConfirmPasswordIcon = confirmPasswordShow ? BsEye : BsEyeSlash;
  return (
    <div style={{ marginTop: 40 }}>
      <Alert error={error} />
      <div>
        <div style={{ width: "100%", position: "relative" }}>
          <div
            style={{
              marginBottom: 5,
              fontSize: 12,
              color: "#6a6a6a",
              fontWeight: 500,
            }}
          >
            New Password
          </div>
          <input
            style={{ paddingRight: 45 }}
            autoComplete="new-password"
            type={newPasswordShow ? "text" : "password"}
            className="custom-input"
            name="password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              setError(null);
            }}
          />
          <PasswordIcon
            onClick={() => setNewPasswordShow(!newPasswordShow)}
            size={20}
            style={{
              position: "absolute",
              cursor: "pointer",
              top: 30,
              right: 10,
            }}
          />
        </div>
        <div style={{ width: "100%", position: "relative" }}>
          <div
            style={{
              marginBottom: 5,
              fontSize: 12,
              color: "#6a6a6a",
              fontWeight: 500,
            }}
          >
            Confirm password
          </div>
          <input
            style={{ paddingRight: 45 }}
            autoComplete="new-password"
            type={confirmPasswordShow ? "text" : "password"}
            className="custom-input"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setError(null);
            }}
          />
          <ConfirmPasswordIcon
            onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
            size={20}
            style={{
              position: "absolute",
              cursor: "pointer",
              top: 30,
              right: 10,
            }}
          />
        </div>
      </div>
      {loading ? (
        <div style={{ textAlign: "center" }}>Loading ....</div>
      ) : (
        <button
          disabled={newPassword !== confirmPassword || loading}
          onClick={() =>
            resetPassword({ variables: { newPassword, token: params.token } })
          }
          style={{
            backgroundColor: "#7CB73F",
            border: "2px solid #7CB73F",
            color: "#fff",
            width: "100%",
          }}
        >
          Reset Password
        </button>
      )}
      <div
        style={{
          textAlign: "center",
          fontSize: 13,
          color: "#6a6a6a",
          fontWeight: 500,
          marginBottom: 40,
          marginTop: 40,
        }}
      >
        Go back to{" "}
        <Link to="/login">
          <span style={{ color: "#212121", fontSize: 13, fontWeight: "600" }}>
            Login page
          </span>
        </Link>
      </div>
    </div>
  );
};

export default PasswordChange;
