import React, { useState } from "react";
import { MUTATION } from "graphql-actions";
import { useMutation } from "@apollo/client";
import { Alert, Input, Button } from "components";
import Form from "../../../../components/Form";
import { useFormik } from "formik";
import { useValidationSchema } from "./hook/use-validation-schema";
import { useInitialValues } from "./hook/use-initial-values";

const Security = () => {
  const [changePassword, { data, loading }] = useMutation(
    MUTATION.AUTH.CHANGE_PASSWORD,
    {
      onError: (err) => setError(err),
      onCompleted: () => formik.resetForm({ values: "" }),
    }
  );

  const [error, setError] = useState(null);

  const onUpdate = ({ oldPassword, newPassword }, actions) => {
    setError(null);

    return changePassword({ variables: { oldPassword, newPassword } });
  };

  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    onSubmit: onUpdate,
    initialValues,
  });

  return (
    <Form formik={formik}>
      <div style={{ width: 500, maxWidth: "100%" }}>
        <div
          style={{
            fontSize: 24,
            color: "#212121",
            fontWeight: 600,
            marginBottom: 20,
          }}
        >
          Change Password
        </div>
        <Alert
          error={error}
          isShow={data?.changePassword}
          message={"Your password has been changed successfully."}
        />
        <div style={{ marginTop: 20 }}>
          <Input.Password label="Old password" name="oldPassword" />
          <Input.Password label="New password" name="newPassword" />
          <Input.Password label="Confirm password" name="confirmPassword" />
        </div>
        <Button
          className="primary"
          disabled={!formik.dirty}
          loading={loading || formik.isSubmitting}
          type="submit"
          title="Change Password"
        />
      </div>
    </Form>
  );
};

export default Security;
