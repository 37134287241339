import * as yup from "yup";

export const useValidationSchema = () => {
  const requiredMessage = "Please fill in the required field.";

  return yup.object({
    oldPassword: yup.string().required(requiredMessage).nullable(),
    newPassword: yup.string().required(requiredMessage).nullable(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required(requiredMessage)
      .nullable(),
  });
};
