import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/Lokshin.png";
import { IoClose } from "react-icons/io5";
import { useAuth } from "../../../utils/authContext/auth";
import clsx from "clsx";

const MobileMenuSlider = ({ isOpen, onClose }) => {
  const nav = useNavigate();
  const auth = useAuth();

  return (
    <div
      style={{ transition: "0.3s", right: !isOpen ? "-95%" : 0 }}
      className={clsx(
        "md:w-[60vw] max-sm:w-[95%] z-[999] h-[100vh] bg-white absolute shadow-[rgb(0 0 0 / 20%)] top-0"
      )}
    >
      <div className="bg-white w-[300px] py-[100px] m-auto relative">
        <div style={{ position: "absolute", top: 20, right: -20 }}>
          <IoClose size={40} onClick={onClose} />
        </div>
        <div style={{ textAlign: "end" }}>
          <a
            href={"https://lokshinrentals.co.za/"}
            className="font-semibold text-sm mb-5 text-[#212121] flex content-end"
          >
            <img
              alt="logo"
              src={logo}
              width={200}
              href="#"
              className="ml-auto"
            />
          </a>
          <ul style={{ listStyle: "none" }}>
            {["About", "Donation", "Properties", "Contact"].map((x, index) => (
              <li
                key={index + x}
                style={{
                  padding: "15px 0px",
                  fontSize: 14,
                  textAlign: "right",
                }}
              >
                {x !== "Properties" ? (
                  <a
                    href={"https://lokshinrentals.co.za/" + x.toLowerCase()}
                    style={{ fontWeight: 600, fontSize: 14, color: "#6a6a6a" }}
                  >
                    {x}
                  </a>
                ) : (
                  <Link
                    to={"/" + x.toLowerCase()}
                    style={{ fontWeight: 600, fontSize: 14, color: "#6a6a6a" }}
                  >
                    {x}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          {!auth.localToken ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => nav("/login")}
                style={{
                  border: "2px solid #212121",
                  color: "#212121",
                  marginRight: 15,
                }}
              >
                Login
              </button>

              <button
                onClick={() => nav("/register")}
                style={{
                  backgroundColor: "#7CB73F",
                  border: "2px solid #7CB73F",
                  color: "#fff",
                }}
              >
                Sign up
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                onClick={() => auth.logout()}
                style={{ border: "2px solid #212121", color: "#212121" }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>

      {/* <div style={{padding:"100px 40px",display:'flex',flexDirection:'column', justifyContent:'center', textAlign:'right'}}>
          <a href={"https://lokshinrentals.co.za/"}  style={{fontWeight:600, fontSize:14, color:'#212121', marginBottom:20}}>
              <img alt="logo" src={logo} width={200} href="#"/>
          </a>
          <ul style={{listStyle:'none',}}>
            {["About", "Donation", "Properties", "Contact"].map((x, index) => (
              <li key={index + x} style={{marginBottom:30, fontSize:14, textAlign:'right',}}>
                {x !== "Properties" ? <a 
                  href={'https://lokshinrentals.co.za/'+x.toLowerCase()}
                  style={{fontWeight:600, fontSize:14, color:'#6a6a6a'}}>
                  {x}
                </a>
                : <Link to={"/"+x.toLowerCase()}  style={{fontWeight:600, fontSize:14, color:'#6a6a6a'}}>{x}</Link>}
              </li>
            ))}
          </ul>
      </div> */}
    </div>
  );
};

export default MobileMenuSlider;
