import styled from "styled-components";

export const StyledMainImageContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 30px;
`;

export const ImageDeleteButton = styled.div`
  height: 20px;
  width: 20px;
  background-color: rgb(30, 30, 30);
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 20px;
  cursor: pointer;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
`;

export const StyledUploadButtonContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  padding: 0px 0px 30px;
`;

export const rowComponentStyle = {};
