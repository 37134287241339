import React, { useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import ReactPaginate from "react-paginate";
import { QUERY } from "graphql-actions";
import {
  Footer,
  NavigationBar,
  PropertyCard,
  PropertyCardShimmer,
  // SearchBar,
} from "components";

import { useEffect } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { favouritesActions } from "../../../store/actions";
import { UserType } from "utils";
import { useParams } from "react-router-dom";

const PropertyList = ({ favActions, account }) => {
  const params = useParams();

  const [getFavs] = useLazyQuery(QUERY.ROOMS.GET_FAVOURITE_ROOMS, {
    skip: account.type === UserType.LANDLORD,
    onCompleted: (data) => {
      favActions.addFavs(data.getFavouriteRooms?.room);
    },
  });
  const { loading, data } = useQuery(QUERY.ROOMS.GET_ALL_ROOMS, {
    onCompleted: () => getFavs(),
  });

  // const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 9;

  useEffect(() => {
    if (!loading && data) {
      let cleanData = data?.getAllRooms;
      if (params.city) {
        cleanData = cleanData.filter((x) =>
          x.address?.fullAddress
            .toLowerCase()
            .includes(params.city.toLocaleLowerCase())
        );
      }
      if (params.township) {
        cleanData = cleanData.filter((x) =>
          x.address?.fullAddress
            .toLowerCase()
            .includes(params.city.toLocaleLowerCase())
        );
      }
      console.log("cleanData", cleanData);
      setFilteredData(cleanData);
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(cleanData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(cleanData?.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage, loading, params]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData?.length;
    setCurrentPage(event?.selected);
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  // const onFilter = (value) => {
  //   const rooms = data?.getAllRooms?.filter((x) =>
  //     JSON.stringify(x).toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredData(rooms);
  //   setCurrentPage(0);
  //   setCurrentItems(rooms?.slice(0, 9));
  //   setPageCount(Math.ceil(rooms?.length / 9));
  // };

  return (
    <div>
      <NavigationBar />
      <div style={{ padding: "180px 0px 100px", backgroundColor: "#F7FCF7" }}>
        <div
          style={{
            maxWidth: 1000,
            margin: "0px auto",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <SearchBar onSearchBar={onFilter} /> */}
          {params?.city && (
            <div className="ml-3 mb-10 text-3xl">
              Showing Rentals in{" "}
              <span className="text-lime-500">
                {params?.city}
                {!params.township ? "" : ", " + params.township}
              </span>
            </div>
          )}
          <section style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {loading &&
              [1, 1, 1, 1, 1, 1].map((_, index) => (
                <PropertyCardShimmer key={index} />
              ))}
            {!loading &&
              currentItems?.map((x, index) => (
                <PropertyCard key={index} property={x} />
              ))}
            {!loading && !currentItems?.length && (
              <div>
                <div
                  style={{
                    fontSize: 16,
                    color: "#6a6a6a",
                    fontWeight: "500",
                    marginBottom: 20,
                  }}
                >
                  No properties found.
                </div>
              </div>
            )}
          </section>
          {!loading && (
            <ReactPaginate
              renderOnZeroPageCount={null}
              previousLabel={
                <BiChevronLeft
                  size={20}
                  style={{ marginTop: -20, marginLeft: 5 }}
                />
              }
              nextLabel={
                <BiChevronRight
                  size={20}
                  style={{ marginTop: -20, marginLeft: 5 }}
                />
              }
              breakLabel="..."
              pageCount={pageCount}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              containerClassName={"pagination"}
              previousLinkClassName={"previous_page"}
              nextLinkClassName={"next_page"}
              activeClassName={"active_number"}
              pageClassName={"inactive_number"}
              marginPagesDisplayed={0}
              pageRangeDisplayed={5}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  favourites: state.favourites,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  favActions: bindActionCreators(favouritesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList);
