import React from 'react'
import { NavigationBar } from '../../components'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
  const nav = useNavigate()
  useEffect(() => {
    if(!window.location.hostname.toLowerCase().includes("platform")){
      return nav("/login")
    }
    return nav("/properties")

  },[nav]);
  
  return (
    <div>
        <NavigationBar/>
    </div>
  )
}

export default Home