import React from "react";
import { IoClose } from "react-icons/io5";
import Button from "../../button";
import Form from "../../Form";
import { Input } from "../..";
import { useFormik } from "formik";

const DeleteModal = ({ isOpen, onClose, onDelete, loading }) => {
  const formik = useFormik({
    initialValues: { status: "Property has been occupied" },
    onSubmit: (values) => onDelete(values),
  });
  if (!isOpen) return null;

  return (
    <div className=" bg-[#1e1e1e98] h-[100vh] w-full flex justify-center items-center fixed top-0 left-0 z-50 ">
      <div className="shadow-lg bg-white w-fit-content justify-center items-center p-10 pt-8 pb-5 relative rounded-md">
        <IoClose
          size={25}
          className="top-2 right-2 absolute cursor-pointer"
          onClick={onClose}
        />
        <h3 className="text-3xl font-medium mb-4">Confirmation</h3>
        <h3 className="text-md font-medium">
          What are your reasons for
          <span className="text-red-600 font-semibold"> removing</span> this
          property from the listing?
        </h3>
        <Form name="delete-form" formik={formik}>
          <Input.RadioButton
            name="status"
            className={"flex-row"}
            isBoolean={false}
            options={[
              "Property has been occupied",
              "I will active it later",
              "Remove permanently",
            ]}
          />
          <div className="flex mt-4">
            <Button
              className="primary small mr-3"
              title="Cancel"
              onClick={onClose}
            />
            <Button
              className="bg-red-600 small text-white border-red-600"
              title="Remove listing"
              type="submit"
              loading={loading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DeleteModal;
