import clsx from "clsx";
import React from "react";

const NavigationLink = ({ Icon, onClick, label, isLast }) => {
  const containerClasses = "flex cursor-pointer items-center w-[230px]";
  return (
    <div
      onClick={onClick}
      className={clsx(containerClasses, {
        "border-b border-[#EAE7E7] mb-4 pb-4": !isLast,
      })}
    >
      <Icon size={20} className="text-[#6a6a6a80] mr-2" />
      <div className="capitalize font-semibold text-[#6a6a6a] text-[13px]">
        {label}
      </div>
    </div>
  );
};

export default NavigationLink;
