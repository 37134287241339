import React from "react";
import { useFormikContext } from "formik";
import { formatter } from "../../../../utils/formatter";
import { BiPencil } from "react-icons/bi";
import { StyledInfoContainer, StyledLabel } from "./roomConfirmation.styles";

const Info = ({ label, value }) => {
  return (
    <StyledInfoContainer>
      <StyledLabel>{label}</StyledLabel>
      <div>{value}</div>
    </StyledInfoContainer>
  );
};
const RoomConfirmation = ({ onEdit }) => {
  const { values } = useFormikContext();

  return (
    <div className="max-w-[650px] w-full mb-7">
      <div
        style={{
          background: "#F5F5F5",
          padding: "5px 30px 20px",
          borderRadius: 5,
          marginBottom: 20,
        }}
      >
        <div className="mb-3">
          <h2 className="text-2xl pt-5">Confirm Details</h2>
          <div className="room-confirm-edit" onClick={() => onEdit(0)}>
            <BiPencil />
          </div>
        </div>
        <Info value={formatter.toRand(values.rent)} label={"Rent"} />
        <Info value={formatter.toRand(values.deposit)} label={"Deposit"} />
        <Info
          value={formatter.booleanToString(values.meterbox)}
          label={"Meterbox"}
        />
        <Info
          value={formatter.booleanToString(values.parking)}
          label={"Parking"}
        />
        <Info
          value={formatter.booleanToString(values.pets)}
          label={"Do you have pets"}
        />
        <Info value={values.rentalType} label={"Rental type"} />
        <Info value={values.bathroomType} label={"Bathroom type"} />
        <Info value={values.availability} label={"Availability"} />
        <Info value={values.description} label={"Description"} />
        <hr className="style-one" style={{ margin: "20px 0px" }} />
        <div style={{ position: "relative" }}>
          <h3>Location</h3>
          <div className="room-confirm-edit" onClick={() => onEdit(1)}>
            <BiPencil />
          </div>
        </div>
        <Info value={values.address.fullAddress} label={"Full address"} />
        <hr className="style-one" style={{ margin: "20px 0px" }} />
        <div style={{ position: "relative" }}>
          <h3>Images</h3>
          <div className="room-confirm-edit" onClick={() => onEdit(2)}>
            <BiPencil />
          </div>
        </div>
        <div className="flex flex-wrap pt-2">
          {values.images.map((url, index) => (
            <img
              key={index}
              className="max-md:w-[46%] w-1/3 h-auto object-cover rounded-md mr-2 mb-2 border"
              alt="house"
              src={url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomConfirmation;
