import { urls } from "../../utils/resuableLists";

const isStaging =
  window.location.hostname.includes("vercel.app") ||
  window.location.hostname.includes("local");
const server = (state = urls[isStaging ? 1 : 2], action) => {
  switch (action.type) {
    case "SWITCH_SERVER":
      return urls[action.number];
    case "REFRESH_SERVER":
      return urls[isStaging ? 1 : 2];
    default:
      return state;
  }
};

export default server;
