import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY, MUTATION } from "graphql-actions";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  Footer,
  NavigationBar,
  FullPageLoader,
  Modals,
  Button,
} from "components";

import SimilarListing from "./SimilarListing";
import RentCard from "./RentCard";
import RoomMap from "./RoomMap";
import RoomSummary from "./RoomSummary";
import RoomDescription from "./RoomDescription";
import RightImages from "./RightImages";
import RoomActions from "./RoomActions";
import { favouritesActions } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import ImageSlideShow from "components/ImageSlideShow";
import { formatter } from "utils";

const imagePlaceholder =
  "https://devtools360.com/assets/img/site-placeholder.jpg";

const PropertyDetails = ({ account, favourites, favouritesActions }) => {
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const params = useParams();
  const navigate = useNavigate();

  const [updateRoom, { loading: updating }] = useMutation(
    MUTATION.ROOM.UPDATE_ROOM,
    {
      refetchQueries: "getAllRooms",
      onError: (error) => console.log(error.message),
      onCompleted: () => navigate("/properties"),
    }
  );

  const { data, loading, error } = useQuery(QUERY.ROOMS.GET_ROOM, {
    skip: !params.id,
    variables: {
      _id: state ? state.property._id : params.id,
    },
    onCompleted: (res) => {
      if (!res.getRoom) return navigate("/properties");
    },
    onError: (res) => navigate("/properties"),
  });
  const { data: similarRoomsData, loading: similarRoomsLoading } = useQuery(
    QUERY.ROOMS.GET_ALL_ROOMS
  );
  useEffect(() => {
    setLoading(true);
    if ((!state && !params.id) || error) {
      return navigate("/properties");
    }
    setLoading(false);
  }, [data, error, loading, navigate, params._id, params.id, state]);

  const shuffle = (array) => {
    if (!array?.length) {
      return [];
    }
    array = array.filter((x) => x._id !== params.id);
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array.slice(0, 3);
  };

  const [addToFavs, { loading: isFavLoading }] = useMutation(
    MUTATION.ROOM.UPDATE_FAVOURITE_ROOMS,
    {
      refetchQueries: "currentUser",
      onCompleted: (res) => {
        const isFav = res.updateFavouriteRoom.room.some(
          (x) => x._id === data?.getRoom._id
        );
        if (isFav) {
          return favouritesActions.addToFavs(data?.getRoom);
        }
        return favouritesActions.removeFavs(data?.getRoom);
      },
    }
  );

  const checkIfFavourite = () => {
    return !!favourites.find((x) => x._id === data?.getRoom._id);
  };

  const onPropertyDelete = async (value) => {
    const status = formatter.getRentalStatus(value);
    return await updateRoom({
      variables: {
        _id: data.getRoom._id,
        input: { active: false, status },
      },
    });
  };

  const onPropertyEdit = (id) =>
    navigate(`/properties/edit-property/${data?.getRoom._id}`);
  console.log(
    "data?.getRoom.user?._id === account?._id",
    data?.getRoom.user?._id === account?._id
  );
  const shouldShow =
    (!data?.getRoom.active && data?.getRoom.user?._id === account?._id) ||
    data?.getRoom.active;
  return (
    <div>
      <NavigationBar />
      <FullPageLoader loading={similarRoomsLoading || loading || isLoading} />

      <Modals.DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={onPropertyDelete}
        loading={updating}
      />
      <ImageSlideShow
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        images={data?.getRoom.images}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      {!(similarRoomsLoading || loading || isLoading) && !shouldShow && (
        <div
          className="property-details-main-container"
          style={{ padding: "200px 0px 0px" }}
        >
          <div
            className="property-details-inner-container mb-20 mx-auto max-md:ml-4"
            style={{ maxWidth: 1000 }}
          >
            <div className="text-5xl mb-6 max-md:text-4xl">
              Room not available anymore
            </div>
            <Button
              title={"Browse more"}
              onClick={() => navigate("/properties")}
            />
          </div>
          <SimilarListing data={shuffle(similarRoomsData?.getAllRooms)} />
        </div>
      )}
      {!(similarRoomsLoading || loading || isLoading) && shouldShow && (
        <div
          className="property-details-main-container"
          style={{ padding: "120px 0px 0px" }}
        >
          <div
            className="property-details-inner-container"
            style={{ maxWidth: 1000, margin: "0px auto" }}
          >
            <section>
              <RoomActions
                room={data?.getRoom}
                account={account}
                onBack={() => navigate("/properties")}
                isFav={checkIfFavourite()}
                isFavLoading={isFavLoading}
                onPropertyDelete={() => setIsModalOpen(true)}
                onPropertyEdit={onPropertyEdit}
                updating={updating}
                onFav={() =>
                  isFavLoading
                    ? null
                    : addToFavs({ variables: { _id: data?.getRoom._id } })
                }
              />

              {/* Image sections */}
              <section className="flex flex-row min-h-[200px]">
                <div
                  className={clsx(
                    "w-full max-md:h-[200px] py-[10px] rounded-md pr-0",
                    {
                      "lg:w-[70%]": data?.getRoom?.images?.[1],
                      "lg:pr-1": data?.getRoom?.images?.[1],
                    }
                  )}
                >
                  <img
                    alt="property"
                    src={
                      data?.getRoom?.images[0] === null
                        ? imagePlaceholder
                        : data?.getRoom?.images[0]
                    }
                    className={clsx(
                      "max-md:p-2 h-[200px] lg:h-[420px] w-full mb-10 object-cover lg:rounded-md rounded-xl",
                      { "cursor-pointer": data?.getRoom?.images[0] }
                    )}
                    onClick={() => {
                      if (data?.getRoom?.images[0]) {
                        setCurrentIndex(0);
                        setOpen(true);
                      }
                    }}
                  />
                </div>
                <div
                  className={clsx("max-md:hidden py-[10px]", {
                    "w-[30%]": data?.getRoom?.images?.[1],
                    "pl-[10px]": data?.getRoom?.images?.[1],
                  })}
                >
                  <RightImages
                    images={data?.getRoom?.images}
                    onImage={(index) => {
                      setCurrentIndex(index);
                      setOpen(true);
                    }}
                  />
                </div>
              </section>

              <section className="lg:flex max">
                {/* Left column */}
                <div className="lg:w-[70%] max-md:w-full p-[10px] lg:pl-0 ">
                  <RoomSummary data={data?.getRoom} account={account} />
                  <RoomDescription data={data?.getRoom} account={account} />
                  <RoomMap
                    address={data?.getRoom?.address}
                    onSimilarListing={null}
                  />
                </div>
                {/* Left column end*/}

                {/* Right column */}
                <div className="lg:w-[30%] max-md:w-full lg:pt-[10px] lg:pl-[10px] max-md:p-2">
                  <RentCard room={data?.getRoom} account={account} />
                </div>
              </section>
            </section>
          </div>
          <SimilarListing data={shuffle(similarRoomsData?.getAllRooms)} />
        </div>
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  favourites: state.favourites,
});

const mapDispatchToProps = (dispatch) => ({
  favouritesActions: bindActionCreators(favouritesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
