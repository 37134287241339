import React from "react";
import Lightbox from "react-spring-lightbox";
import { IoChevronBack, IoChevronForward, IoClose } from "react-icons/io5";

const ImageSlideShow = ({
  images,
  isOpen,
  currentIndex,
  setCurrentIndex,
  onClose,
}) => {
  const gotoPrevious = () =>
    currentIndex > 0
      ? setCurrentIndex(currentIndex - 1)
      : setCurrentIndex(images.length - 1);

  const gotoNext = () =>
    currentIndex + 1 < images.length
      ? setCurrentIndex(currentIndex + 1)
      : setCurrentIndex(0);
  return (
    <Lightbox
      isOpen={isOpen}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={images?.map((image, index) => ({
        src: image,
        loading: "lazy",
        alt: "rental-property-image-" + index,
      }))}
      currentIndex={currentIndex}
      renderPrevButton={() => (
        <IoChevronBack
          onClick={gotoPrevious}
          color="white"
          size={50}
          className="lg:ml-10 ml-5 z-10 cursor-pointer"
        />
      )}
      renderNextButton={() => (
        <IoChevronForward
          onClick={gotoNext}
          color="white"
          size={50}
          className="lg:mr-10 mr-5 z-10 cursor-pointer"
        />
      )}
      renderHeader={() => (
        <div className="p-6 bg-black text-white flex w-full max-lg:mt-[80px]">
          <div className="ml-auto">
            <IoClose className="cursor-pointer" size={30} onClick={onClose} />
          </div>
        </div>
      )}
      renderFooter={() => (
        <div className="p-4 bg-black text-white flex w-full z-10">
          <div className="m-auto">
            {currentIndex + 1 + " / " + images?.length}
          </div>
        </div>
      )}
      style={{ background: "black" }}
    />
  );
};

export default ImageSlideShow;
