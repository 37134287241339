import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IoSearch } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, PropertyCard } from "components";
import { useQuery } from "@apollo/client";
import { bindActionCreators } from "redux";
import { favouritesActions } from "../../../../store/actions";
import { QUERY } from "graphql-actions";
import { Oval } from "react-loader-spinner";

const SavedProperties = ({ account, favourites, favActions }) => {
  const { loading } = useQuery(QUERY.ROOMS.GET_FAVOURITE_ROOMS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      favActions.addFavs(data.getFavouriteRooms?.room);
    },
  });

  const navigate = useNavigate();
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 3;

  useEffect(() => {
    if (!loading) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(favourites?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(favourites?.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, favourites, loading]);

  useEffect(() => {
    if (currentPage !== 0 && !currentItems?.length) {
      const newOffset = ((currentPage - 1) * itemsPerPage) % favourites?.length;
      setCurrentPage(currentPage - 1);
      setItemOffset(newOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % favourites?.length;
    setCurrentPage(event?.selected);
    setItemOffset(newOffset);
    // window.scrollTo(0, 0);
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          color: "#212121",
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        Saved Properties
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {!loading &&
          currentItems?.map((property, index) => (
            <PropertyCard key={index} property={property} />
          ))}
      </div>
      {!loading && !currentItems?.length && (
        <>
          <div
            style={{
              fontSize: 16,
              color: "#6a6a6a",
              fontWeight: "500",
              marginBottom: 20,
            }}
          >
            You have no saved properties.
          </div>
          <Button
            leftIcon={<IoSearch size={16} />}
            title="Browse all properties"
            onClick={() => navigate("/properties")}
            className="button dark small"
          />
        </>
      )}
      <Oval
        height={30}
        width={30}
        color="#7CB73F"
        wrapperStyle={{}}
        wrapperClass=""
        visible={loading}
        ariaLabel="oval-loading"
        secondaryColor="#7CB73F"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
      {!loading && (
        <div>
          <ReactPaginate
            renderOnZeroPageCount={null}
            previousLabel={
              <BiChevronLeft
                size={20}
                style={{ marginTop: -20, marginLeft: 5 }}
              />
            }
            nextLabel={
              <BiChevronRight
                size={20}
                style={{ marginTop: -20, marginLeft: 5 }}
              />
            }
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageClick}
            forcePage={currentPage}
            containerClassName={"pagination"}
            previousLinkClassName={"previous_page"}
            nextLinkClassName={"next_page"}
            activeClassName={"active_number"}
            pageClassName={"inactive_number"}
            marginPagesDisplayed={0}
            pageRangeDisplayed={5}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  favourites: state.favourites,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  favActions: bindActionCreators(favouritesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedProperties);
