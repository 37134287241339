import React from "react";
import ContentLoader from "react-content-loader";

const PropertyCardShimmer = () => {
  return (
    <div className="lg:w-[33%] max-md:flex-wrap max-md:mx-auto">
      <ContentLoader
        speed={2}
        width={320}
        height={460}
        viewBox="0 0 320 460"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="5" y="18" rx="5" ry="5" width="375" height="200" />
        <rect x="20" y="238" rx="0" ry="0" width="184" height="20" />
        <rect x="20" y="311" rx="0" ry="0" width="287" height="10" />
        <circle cx="281" cy="253" r="25" />
        <rect x="20" y="274" rx="0" ry="0" width="184" height="20" />
        <rect x="20" y="344" rx="0" ry="0" width="286" height="4" />
        <rect x="20" y="361" rx="0" ry="0" width="60" height="15" />
        <rect x="107" y="362" rx="0" ry="0" width="60" height="15" />
        <rect x="206" y="362" rx="0" ry="0" width="60" height="15" />
      </ContentLoader>
    </div>
  );
};

export default PropertyCardShimmer;
