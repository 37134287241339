import { useFormikContext } from "formik";
import React from "react";
import { formatter } from "../../../utils";
import {
  StyledRadioButtonCircle,
  StyledRadioButtonContainer,
  StyledRadioButtonInnerCircle,
  StyledRadioLabel,
  StyledRadioOptionLabel,
} from "./radioButton.styles";

const RadioButton = ({
  label,
  name,
  options = [],
  onOption,
  isBoolean = true,
  className,
}) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);

  return (
    <div className={className}>
      <StyledRadioLabel>{label}</StyledRadioLabel>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginTop: 10,
        }}
      >
        {options.map((option, index) => {
          const active = meta.value === option;
          const label = isBoolean ? formatter.booleanToString(option) : option;
          return (
            <StyledRadioButtonContainer
              key={index}
              onClick={() => {
                // setSelectedOption(option);
                setFieldValue(name, option);
                onOption?.(option);
              }}
            >
              <StyledRadioOptionLabel>{label}</StyledRadioOptionLabel>
              <StyledRadioButtonCircle>
                <StyledRadioButtonInnerCircle active={active} />
              </StyledRadioButtonCircle>
            </StyledRadioButtonContainer>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButton;
