import * as yup from "yup";

export const useValidationSchema = () => {
  const requiredMessage = "Please fill in the required field.";

  return yup.object({
    firstName: yup.string().required(requiredMessage).nullable(),
    lastName: yup.string().required(requiredMessage).nullable(),
    profilePicture: yup.mixed(),
    phoneNumber: yup.string().required(requiredMessage).nullable(),
    whatsappNumber: yup.string().required(requiredMessage).nullable(),
  });
};
