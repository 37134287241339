import React from "react";
import { FaCheck } from "react-icons/fa";

import { clsx } from "clsx";

const Stepper = ({ steps, activeStep, completedSteps, onClick }) => {
  return (
    <div className="flex w-[610px] justify-between text-sm">
      {steps.map((step, index) => {
        const completed = completedSteps.find((x) => x === index.toString());
        const isActive = activeStep === index;
        return (
          <div
            className="flex items-center"
            style={{ cursor: "pointer" }}
            key={index}
            onClick={() => completed && onClick(index)}
          >
            <div
              className={clsx(
                "w-8 h-8 bg-[#f5f5f5] flex items-center justify-center mr-2 rounded-md text-[#3C3C43]",
                {
                  "!bg-[#7DB73E] !text-white": isActive,
                  "text-white": activeStep > index,
                }
              )}
            >
              {completed && !isActive ? <FaCheck color="green" /> : index + 1}
            </div>
            <span style={{ fontWeight: isActive ? "600" : "500" }}>
              {step.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
