import { useMutation } from "@apollo/client";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { accountActions, authActions } from "../../../store/actions";
import { useAuth } from "../../../utils/authContext/auth";
import { MUTATION } from "graphql-actions";

import { Alert, Footer, NavigationBar } from "components";
import EmailReset from "./emailReset";
import PasswordChange from "./passwordChange";

const ForgotPassword = ({ acountActions, authActions }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [verifyForgotPasswordLink, { loading }] = useMutation(
    MUTATION.AUTH.VERIFY_FORGOT_PASSWORD_LINK,
    {
      onCompleted: () => {
        setResetPassword(true);
        setIsSuccess(false);
      },
      onError: (error) => setError(error),
    }
  );

  const nav = useNavigate();
  const params = useParams();

  const auth = useAuth();

  useEffect(() => {
    if (auth.localToken) {
      return nav("/properties", { replace: true });
    }
  }, [auth.localToken, nav]);

  useEffect(() => {
    setResetPassword(false);
    if (params?.id && params?.token) {
      // verify params then
      verifyForgotPasswordLink({
        variables: {
          _id: params?.id,
          token: params?.token,
        },
      });
    }
  }, [acountActions, auth, authActions, nav, params, verifyForgotPasswordLink]);

  return (
    <div>
      <NavigationBar />
      <section
        style={{
          padding: "180px 0px 60px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ maxWidth: 1000, margin: "auto" }}>
          {loading && (
            <div
              style={{
                width: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 400,
              }}
            >
              <h3 style={{}}>Loading ...</h3>
            </div>
          )}
          {!loading && (
            <div
              style={{
                width: 600,
                borderRadius: 5,
                padding: "0px 20px",
                boxSizing: "border-box",
                maxWidth: "100vw",
              }}
            >
              {!isSuccess && (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 32,
                      color: "#212121",
                      fontWeight: 600,
                      marginBottom: 20,
                    }}
                  >
                    Reset your password
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 16,
                      color: "#6a6a6a",
                      fontWeight: 500,
                    }}
                  >
                    Enter your email address and we'll send you instructions
                    <br />
                    on how to reset your password.
                  </div>
                  <Alert error={error} />

                  {!resetPassword && (
                    <EmailReset
                      email={email}
                      onChange={(e) => {
                        setEmail(e);
                        setError(null);
                      }}
                    />
                  )}
                  {resetPassword && (
                    <PasswordChange onSuccess={() => setIsSuccess(true)} />
                  )}
                </>
              )}
              {isSuccess && (
                <div
                  style={{
                    maxWidth: 600,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 32,
                      color: "#212121",
                      fontWeight: 600,
                      marginBottom: 20,
                    }}
                  >
                    Passsword Changed Sucessfully
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 16,
                      color: "#6a6a6a",
                      fontWeight: 500,
                    }}
                  >
                    You have <b>successfully reset your password</b>.<br />
                    Please login with your <b>email</b> again.
                  </div>
                  <img
                    src={require("../../../assets/animated-tick.gif")}
                    alt="success-icon"
                    style={{ height: 150, display: "flex", margin: "auto" }}
                  />
                  <div style={{ width: "50%", marginTop: 20 }}>
                    <Link to="/login">
                      <button
                        style={{
                          backgroundColor: "#212121",
                          color: "#fff",
                          width: "100%",
                          marginBottom: 10,
                        }}
                      >
                        Login
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
      <Footer authFooter noBorder={window.innerHeight > 769} />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
