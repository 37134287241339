import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./utils/authContext/auth";
import { RequireAuth } from "./utils/authContext/requireAuth";
import { Auth, Home, Profile, Property, Search } from "./pages";
import { UserType } from "utils";

export const ProtectedRoutes = [
  {
    path: "/account",
    Element: Profile.Account,
    type: "all",
  },
  {
    path: "/properties/add-property",
    Element: Property.AddProperty,
    type: UserType.LANDLORD,
  },
  {
    path: "/properties/edit-property/:id",
    Element: Property.AddProperty,
    type: UserType.LANDLORD,
  },
];

const isProduction = window.location.hostname
  ?.toLowerCase()
  .includes("platform");
export default (
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        {!isProduction && (
          <>
            <Route path={`/`} element={<Home />} />
            <Route path="/search" element={<Search />} />
          </>
        )}
        <Route index path={`/login`} element={<Auth.Login />} />
        <Route path={`/forgot-password`} element={<Auth.ForgotPassword />} />
        <Route
          path={`/forgot-password/:id/:token`}
          element={<Auth.ForgotPassword />}
        />
        <Route path="/register" exact element={<Auth.Register />} />
        <Route path="/properties" element={<Property.PropertyList />} />
        <Route
          path="/properties/:city/:township?"
          element={<Property.PropertyList />}
        />
        <Route
          path="/properties/property/:city/:township/:id"
          element={<Property.PropertyDetails />}
        />

        {/* user properties */}
        {/* <Route
          path="/properties/user/:firstName/:id"
          element={<Property.PropertyList />}
        /> */}
        {ProtectedRoutes.map((PR, index) => (
          <Route
            key={index}
            path={PR.path}
            element={
              <RequireAuth>
                <PR.Element />
              </RequireAuth>
            }
          />
        ))}
        <Route path="*" element={<Property.PropertyList />} />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);
