import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { NavigationBar, Footer, Alert, Button, Input } from "components";
import { TbHome } from "react-icons/tb";
import { BiUser } from "react-icons/bi";
import { useAuth } from "../../../utils/authContext/auth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { accountActions, authActions } from "../../../store/actions";
import { MUTATION } from "graphql-actions";
import { useMutation } from "@apollo/client";
import Form from "components/Form";
import { useFormik } from "formik";
import { useValidationSchema } from "./hook/use-validation-schema";
import { useInitialValues } from "./hook/use-initial-values";
import UserTypeSelect from "./components/UserTypeSelect";

const Register = ({ acountActions, authActions }) => {
  const nav = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [error, setError] = useState(null);

  const [register, { data, loading }] = useMutation(
    MUTATION.AUTH.SIGN_UP_USER,
    {
      onError: (err) => setError(err),
    }
  );

  useEffect(() => {
    if (auth.localToken) {
      return nav("/account", { replace: true });
    }
  }, [auth.localToken, nav]);

  useEffect(() => {
    if (!loading && data) {
      authActions.addToken(data.signupUser.token);
      auth.login(data.signupUser.token);
      const url = location.state ?? "/account";
      return nav(url, { replace: true });
    }
  }, [acountActions, auth, authActions, data, loading, location.state, nav]);

  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      return register({
        variables: {
          input: {
            email: values.email.trim(),
            phoneNumber: values.phoneNumber.trim(),
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            password: values.password,
            whatsappNumber: values.whatsappNumber,
            type: values.type,
          },
        },
      });
    },
  });

  console.log(formik.values);
  return (
    <Form formik={formik}>
      <NavigationBar />
      <section className="flex flex-col pt-44 pb-14">
        <div style={{ maxWidth: 1000, margin: "auto" }}>
          <div className=" w-[600px] rounded px-5 box-border max-w-[100vw]">
            <div className="text-center text-3xl text-[#212121] font-semibold mb-5">
              Register
            </div>
            <div className="text-center text-base text-[#6a6a6a] font-medium ">
              We’re here to help and answer any question you might have. We look
              forward to hearing from you.
            </div>
            <div style={{ marginTop: 60 }}>
              <Alert error={error} />
              <div className="flex flex-row justify-between">
                <div className="w-6/12 mr-3">
                  <Input.TextField name="firstName" label="First name" />
                </div>
                <div className="w-6/12">
                  <Input.TextField name="lastName" label="Last name" />
                </div>
              </div>

              <div className="flex flex-row justify-between">
                <div className="w-6/12 mr-3">
                  <Input.TextField name="phoneNumber" label="Phone number" />
                </div>
                <div className="w-6/12">
                  <Input.TextField
                    name="whatsappNumber"
                    label="Whatsapp number"
                  />
                </div>
              </div>
              <Input.TextField
                name="email"
                label="Email address"
                type="email"
              />
              <div className="flex flex-row justify-between mb-2">
                <div className="w-6/12 mr-3">
                  <Input.Password name="password" label="Password" />
                </div>
                <div className="w-6/12">
                  <Input.Password
                    name="confirmPassword"
                    label="Confirm Password"
                  />
                </div>
              </div>

              <div className="text-xs text-[#6a6a6a] font-medium mb-4">
                <span>Type of user</span>
                <div className="flex flex-row mt-4">
                  <UserTypeSelect name="Landlord" Icon={TbHome} />
                  <UserTypeSelect name="Tenant" Icon={BiUser} />
                </div>
              </div>
              {/* {formik.values.type === UserType.LANDLORD && (
                
              )} */}
              <Button
                className="primary full-width"
                title=" Sign up"
                loading={loading}
                type="submit"
              />

              <div className="text-[#6a6a6a] text-xs my-10 text-center font-medium">
                Already have an account?{" "}
                <Link to="/login">
                  <span className="text-[#212121] text-xs font-semibold">
                    Login
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer authFooter />
    </Form>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
