import moment from "moment";
import { useMemo } from "react";

export const useInitialValues = (data) => {
  return useMemo(() => {
    if (data) {
      return {
        _id: data._id,
        rent: data.rent ?? "",
        deposit: data.deposit ?? 0,
        meterbox: data.meterbox,
        parking: data.parking,
        pets: data.pets,
        kids: data.kids ?? true,
        rentalType: data.rentalType,
        bathroomType: data.bathroomType,
        availability: moment(data.availability).format("YYYY-MM-DD"),
        description: data.description,
        address: data.address,
        images: data.images,
      };
    }
    return {
      rent: null,
      deposit: 0,
      meterbox: false,
      parking: false,
      pets: false,
      kids: true,
      rentalType: "",
      bathroomType: "",
      availability: moment(new Date()).format("YYYY-MM-DD"),
      description: "",
      address: {
        placeId: "",
        fullAddress: "",
        geo: {
          lat: "",
          lng: "",
        },
      },
      images: [],
    };
  }, [data]);
};
