import React from "react";

const Alert = ({ error, isShow, message }) => {
  if (error && error?.message) {
    return (
      <div
        style={{
          transition: "0.3s",
          textAlign: "center",
          fontSize: error?.message ? 14 : 0,
          color: "red",
          fontWeight: 600,
          margin: error?.message ? "15px 0px" : 0,
          padding: error?.message ? "15px 0px" : 0,
          backgroundColor: "#F7F7F7",
          borderLeft: "4px solid red",
        }}
      >
        {error?.message}
      </div>
    );
  }
  if (isShow && message) {
    return (
      <div
        style={{
          transition: "0.3s",
          textAlign: "center",
          fontSize: isShow ? 14 : 0,
          color: "#7CB73F",
          fontWeight: 600,
          margin: isShow ? "15px 0px" : 0,
          padding: isShow ? "15px 0px" : 0,
          backgroundColor: "#f7f7f7",
          borderLeft: "4px solid #7CB73F",
        }}
      >
        {message}
      </div>
    );
  }
  return null;
};

export default Alert;
