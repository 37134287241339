import { gql } from "@apollo/client";

export const GET_ROOM = gql`
  query getRoom($_id: ID!) {
    getRoom(_id: $_id) {
      _id
      roomNumber
      user {
        _id
        firstName
        lastName
        profilePicture
        email
        phoneNumber
        whatsappNumber
        type
        createdDate
      }
      rent
      deposit
      meterbox
      parking
      pets
      kids
      rentalType
      bathroomType
      availability
      description
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
      status
      active
      views
      images
      createdDate
    }
  }
`;

export const GET_USER_ROOMS = gql`
  query getUserRooms {
    getUserRooms {
      _id
      roomNumber
      user {
        _id
        firstName
        lastName
        email
        type
        createdDate
      }
      rent
      deposit
      meterbox
      parking
      pets
      kids
      rentalType
      bathroomType
      availability
      description
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
      status
      active
      views
      images
      createdDate
    }
  }
`;

export const GET_ALL_ROOMS = gql`
  query getAllRooms {
    getAllRooms {
      _id
      roomNumber
      user {
        _id
        firstName
        lastName
        email
        type
        createdDate
      }
      rent
      deposit
      meterbox
      parking
      pets
      kids
      rentalType
      bathroomType
      availability
      description
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
      status
      active
      views
      images
      createdDate
    }
  }
`;

export const FILTER_ROOMS = gql`
  query filterRooms($input: filterInput) {
    filterRooms(input: $input) {
      _id
      roomNumber
      user {
        _id
        firstName
        lastName
        email
        type
        createdDate
      }
      rent
      deposit
      meterbox
      parking
      pets
      rentalType
      bathroomType
      availability
      description
      address {
        fullAddress
        placeId
        geo {
          lat
          lng
        }
      }
      status
      active
      views
      images
      createdDate
    }
  }
`;

export const GET_FAVOURITE_ROOMS = gql`
  query getFavouriteRooms {
    getFavouriteRooms {
      room {
        _id
        roomNumber
        user {
          _id
          firstName
          lastName
          profilePicture
          email
          type
          createdDate
        }
        rent
        deposit
        meterbox
        parking
        pets
        kids
        rentalType
        bathroomType
        availability
        description
        address {
          fullAddress
          placeId
          geo {
            lat
            lng
          }
        }
        status
        active
        views
        images
        createdDate
      }
      user {
        _id
      }
    }
  }
`;
