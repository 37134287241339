/* eslint-disable no-unused-vars */
import React from "react";
import {
  Footer,
  NavigationBar,
  PropertyCard,
  PropertyCardShimmer,
  SearchBar,
} from "../../components";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { formatter } from "../../utils";
import { useLazyQuery } from "@apollo/client";
import { QUERY } from "../../graphql-actions";
import ReactPaginate from "react-paginate";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IoSearch } from "react-icons/io5";

const Search = () => {
  const [roomFilter, { data, loading }] = useLazyQuery(
    QUERY.ROOMS.FILTER_ROOMS
  );
  const state = useLocation();
  const navigate = useNavigate();
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    const search = state.search;
    let location;
    let when;
    let price;
    let property_type;
    if (search.includes("location"))
      location = formatter.searchSplitter(search, "location");
    if (search.includes("when"))
      when = formatter.searchSplitter(search, "when");
    if (search.includes("price"))
      price = formatter.searchSplitter(search, "price");
    if (search.includes("property_type"))
      property_type = formatter.searchSplitter(search, "property_type");

    async function fetchData() {
      const input = {
        parking: location,
        bathroom: "",
        deposit: "",
        availability: "",
        rent: "",
      };
      await roomFilter();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!loading) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data?.filterRooms?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data?.filterRooms?.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.filterRooms, itemOffset, itemsPerPage, loading]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % data?.filterRooms?.length;
    setCurrentPage(event?.selected);
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <NavigationBar />
      <div style={{ padding: "180px 0px 100px", backgroundColor: "#F7FCF7" }}>
        <div style={{ maxWidth: 1000, margin: "0px auto", minHeight: "28vh" }}>
          <h1 style={{ textAlign: "center", marginBottom: -20 }}>
            Search Results
          </h1>
          <SearchBar title="" />
          <section
            style={{ display: "flex", flexWrap: "wrap", margin: "0px auto" }}
          >
            {loading &&
              [1, 1, 1, 1, 1, 1].map((_, index) => (
                <PropertyCardShimmer key={index} />
              ))}
            {!loading &&
              currentItems?.map((x, index) => (
                <PropertyCard key={index} index={index} property={x} />
              ))}
            {!loading && !currentItems?.length && (
              <div>
                <div
                  style={{
                    fontSize: 16,
                    color: "#6a6a6a",
                    fontWeight: "500",
                    marginBottom: 20,
                  }}
                >
                  No properties found.
                </div>
                <button
                  onClick={() => navigate("/properties")}
                  style={{
                    backgroundColor: "#212121",
                    border: "1px solid #212121",
                    color: "#fff",
                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IoSearch size={16} style={{ marginRight: 5 }} />
                  <div style={{ fontSize: 13, fontWeight: 500 }}>
                    Browse all properties
                  </div>
                </button>
              </div>
            )}
          </section>
          {!loading && data?.getAllRooms?.length > itemsPerPage && (
            <ReactPaginate
              renderOnZeroPageCount={null}
              previousLabel={
                <BiChevronLeft
                  size={20}
                  style={{ marginTop: 3, marginLeft: 5 }}
                />
              }
              nextLabel={
                <BiChevronRight
                  size={20}
                  style={{ marginTop: 3, marginLeft: 5 }}
                />
              }
              breakLabel="..."
              pageCount={pageCount}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              containerClassName={"pagination"}
              previousLinkClassName={"previous_page"}
              nextLinkClassName={"next_page"}
              activeClassName={"active_number"}
              pageClassName={"inactive_number"}
              pageRangeDisplayed={5}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Search;
