import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { BiShareAlt, BiEdit, BiTrash } from "react-icons/bi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import clsx from "clsx";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { IoClose } from "react-icons/io5";
import { UserType, formatter } from "utils";
import { Button } from "components";
import { useNavigate } from "react-router-dom";
const RoomActions = ({
  room,
  onBack,
  onFav,
  isFav,
  account,
  onPropertyEdit,
  onPropertyDelete,
  updating,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const Icon = isFav ? FaHeart : FaRegHeart;
  const caption =
    "Check out this beautiful rentals in a good location! Convenient location near local shops and restaurants, this property won't last long.";

  const buttonClass =
    "bg-[#7CB73F10] border-solid border-[#7DB73E] border flex text-[#7CB73F] items-center relative";
  const textClass = "max-lg:hidden text-sm text-[#7DB73E] font-medium ml-2";
  const isLandlord = account.type === UserType.LANDLORD;
  const isOwner = account?._id === room?.user?._id;
  const nav = useNavigate();
  const { city } = formatter.propertyAddress(room?.address?.fullAddress);
  return (
    <div className="p-2 lg:p-0 relative">
      {isOpen && (
        <div className=" bg-[#1e1e1e98] h-[100vh] w-full flex justify-center items-center fixed top-0 left-0 z-50 ">
          <div className=" bg-gray-300 w-fit-content justify-center items-center p-10 pb-5 relative rounded-md">
            <IoClose
              size={25}
              className="top-2 right-2 absolute cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
            <h3 className="text-md font-medium">
              Share this property using the following:
            </h3>
            <div className=" flex justify-center items-center p-3">
              <EmailShareButton
                url={window.location.href}
                subject={caption}
                body="body"
                className="!p-0 mr-3"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <FacebookShareButton
                url={"https://lokshinrentals.co.za"}
                quote={caption}
                title={caption}
                hashtag="#lokshinrentals #kasisoftware"
                className="!p-0 mr-3"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={window.location.href}
                title={caption}
                className="!p-0 mr-3"
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <WhatsappShareButton
                title={caption}
                url={window.location.href}
                separator=" "
                className="!p-0"
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={onBack}
        className="flex cursor-pointer text-sm font-semibold text-[#7DB73E] items-center"
      >
        <FaChevronLeft className="mr-1" />
        <div> Back to List</div>
      </div>
      <div className="justify-between md:flex">
        <div className="w-6/12 max-sm:w-full">
          <h1 className="mt-3 mb-2">{room?.address?.township}</h1>
          <p className="text-[#6a6a6a] text-base font-medium">
            {room?.address?.fullAddress?.replace(",undefined", "")}
          </p>
        </div>

        <div className="max-md:w-full  md:justify-end flex w-6/12 max-md:mt-3">
          <Button
            leftIcon={<BiShareAlt size={16} className="text-[#7DB73E]" />}
            title={"Share"}
            className={buttonClass}
            onClick={() => setIsOpen(true)}
            textClass={textClass}
          />
          {!isOwner && (
            <Button
              leftIcon={<IoSearch size={16} className="text-[#7DB73E]" />}
              title={"Browse nearby listings"}
              className={clsx(buttonClass, "ml-2")}
              onClick={() => nav(`/properties/${city}/`)}
              textClass={textClass}
            />
          )}
          {!isLandlord ? (
            <>
              {account?._id && (
                <Button
                  leftIcon={<Icon size={16} />}
                  title={isFav ? "Remove" : "Favorite"}
                  className={clsx(buttonClass, "ml-2")}
                  onClick={onFav}
                  textClass={textClass}
                />
              )}
            </>
          ) : (
            isOwner && (
              <>
                <Button
                  leftIcon={<BiEdit size={16} className="text-white" />}
                  title={" Edit Property"}
                  className="ml-2 primary"
                  onClick={onPropertyEdit}
                  textClass={clsx(`${textClass} text-white`)}
                />
                <Button
                  leftIcon={<BiTrash size={16} className="text-white" />}
                  title={"Delete"}
                  className="ml-2 danger"
                  onClick={onPropertyDelete}
                  loading={updating}
                  textClass={clsx(`${textClass} text-white`)}
                />
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomActions;
