import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation ($profilePicture: String, $input: UserInput) {
    updateProfile(profilePicture: $profilePicture, input: $input) {
      _id
      firstName
      lastName
      email
      phoneNumber
      whatsappNumber
      type
      profilePicture
      status
      createdDate
    }
  }
`;
