import { useQuery } from "@apollo/client";
import { GET_ROOM } from "graphql-actions/queries/rooms";

export const useGetProperty = (_id) => {
  return useQuery(GET_ROOM, {
    variables: { _id },
    skip: !_id,
    onError: (error) => {
      // navigate("/account/")
      console.log(error);
    },
  });
};
