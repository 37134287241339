import formValidators from "./formValidators";
import { formatter } from "./formatter";
import { fileUploader } from "./fileUploader";

const UserType = {
  TENANT: "tenant",
  LANDLORD: "landlord",
};

const RentalType = {
  ROOM: "room",
  BACHELOR: "bachelor",
  COTTAGE: "cottage",
  GARAGE: "garage",
  HOUSE: "house",
};

const RENTAL_STATUS = {
  OCCUPIED: "occupied",
  ARCHIVED: "archived",
  DELETED: "deleted",
};

export {
  formValidators,
  formatter,
  fileUploader,
  UserType,
  RentalType,
  RENTAL_STATUS,
};
