import { UserType } from "utils";

export const useInitialValues = () => {
  return {
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    whatsappNumber: "",
    firstName: "",
    lastName: "",
    type: UserType.LANDLORD,
  };
};
