import React from "react";
import { BiBath } from "react-icons/bi";
import { IoCarSportOutline } from "react-icons/io5";
import { TbBolt } from "react-icons/tb";
import { AiOutlineCheckCircle } from "react-icons/ai";
import moment from "moment";
import { formatter } from "../../../../utils/formatter";
import { Button } from "components";
import { CgFileDocument } from "react-icons/cg";
import { FaBaby } from "react-icons/fa";

function isAfterToday(date, status) {
  if (status && date) {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const roomDate = moment(date).format("YYYY-MM-DD");
    return !moment(roomDate).isSameOrBefore(today)
      ? "Yes"
      : moment(date).format("YYYY-MM-DD");
  }
  return "Not Available";
}

const RoomSummary = ({ data }) => {
  const isProduction = window.location.hostname
    .toLowerCase()
    .includes("platform");
  const summary = [
    {
      title: "Bathroom",
      value: data?.bathroomType,
      Icon: BiBath,
    },
    {
      title: "Meter",
      value: formatter.booleanToString(data?.meterbox),
      Icon: TbBolt,
    },
    {
      title: "Parking",
      value: formatter.booleanToString(data?.parking),
      Icon: IoCarSportOutline,
    },
    {
      title: "Kids Allowed",
      value: formatter.booleanToString(data?.kids ?? true),
      Icon: FaBaby,
    },
    {
      title: "Availability",
      value: isAfterToday(data?.availability, data?.status),
      Icon: AiOutlineCheckCircle,
    },
  ];
  return (
    <div>
      <div className="lg:hidden p-2 rounded border-[#e9e9e9] border my-3">
        <p className="m-0 text-xs text-[#6a6a6a] font-medium">Rent price</p>
        <div className="flex mt-2">
          <div className="text-[#7CB73F] font-extrabold text-xl">
            {formatter.toRand(data?.rent)}
          </div>
          <div className="mt-2 ml-1 text-xs text-[#6a6a6a] font-medium">
            /month
          </div>
        </div>
        {data?.deposit > 0 && (
          <div className="flex text-sm">
            <span className=" font-extrabold  mr-1">Deposit -</span>
            <span className="text-[#7CB73F] font-extrabold">
              {formatter.toRand(data?.deposit)}
            </span>
          </div>
        )}
        {!isProduction && (
          <Button
            className="primary full-width !px-3 !py-2 mt-3"
            leftIcon={<CgFileDocument />}
            title={"Apply now"}
            onClick={null}
          />
        )}
      </div>
      <div className="flex bg-white px-6 py-5 max-md:p-3 mb-10 justify-between rounded-[5px] border border-solid border-[#e9e9e9] w-full max-md:mb-5">
        {summary.map((x, index) => {
          return (
            <div key={index} className="justify-between">
              <div className="text-sm max-md:text-xs text-[#6a6a6a] font-semibold mb-2 max-md:text-center">
                {x.title}
              </div>
              <div className="lg:flex items-center text-center">
                <x.Icon
                  size={20}
                  className="text-[#6a6a6a] max-md:mb-1 lg:mr-1 max-md:mx-auto"
                />
                <div className="max-md:text-xs font-bold text-[#212121]">
                  {x.value}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoomSummary;
