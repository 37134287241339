import styled from "styled-components";

export const StyledInputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledImagePreviewContainer = styled.div`
  margin: 20px 0px;
  position: relative;
`;

export const StyledImageRemoveButton = styled.div`
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 5px 20px -3px rgb(0 0 0 / 40%);
  display: flex;
  position: absolute;
  left: 75px;
`;

export const StyledImagePreview = styled.img.attrs((props) => ({
  alt: "lokhin-rental-user-avatar",
}))`
  border: 2px solid #fff;
  box-shadow: 0px 5px 20px -3px rgb(0 0 0 / 40%);
  background-color: #f5f5f5;
  transition: 0.3s;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
`;
