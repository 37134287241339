import clsx from "clsx";
import React from "react";
import { FaImage } from "react-icons/fa";
const imagePlaceholder =
  "https://devtools360.com/assets/img/site-placeholder.jpg";

const RightImages = ({ images, onImage }) => {
  return (
    <div style={{ marginBottom: 40, minHeight: 425 }}>
      {images?.[1] && (
        <>
          <img
            alt="lokshin-rental-property"
            src={!images[1] ? imagePlaceholder : images[1]}
            onClick={() => {
              if (images[1]) {
                onImage(1);
              }
            }}
            onMouseEnter={() => console.log(1)}
            className={clsx(
              "object-cover h-[200px] w-full rounded-md mb-5 bg-black",
              {
                "cursor-pointer": images[1],
              }
            )}
          />
          <div style={{ position: "relative" }}>
            <img
              alt="lokshin-rental-property"
              src={!images[2] ? imagePlaceholder : images[2]}
              onClick={() => {
                if (images[2]) {
                  onImage(2);
                }
              }}
              onMouseEnter={() => console.log(2)}
              className={clsx(
                "object-cover h-[200px] w-full rounded-md bg-black",
                {
                  "cursor-pointer": images[2],
                }
              )}
            />
            {images?.length > 3 && (
              <button
                className="small"
                onClick={() => onImage(0)}
                style={{
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                  border: "none",
                  fontWeight: "700",
                  color: "#212121",
                  marginRight: 15,
                  padding: "15px 15px",
                  fontSize: 11,
                  position: "absolute",
                  bottom: 20,
                  right: 10,
                  zIndex: 2,
                }}
              >
                <FaImage
                  size={12}
                  style={{ marginRight: 8, color: "#7DB73E" }}
                />
                <div>View all photos</div>
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RightImages;
