import {
  ADD_FAVORITES,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
} from "./actionTypes";

// ACCOUNT ACTIONS
export const addFavs = (rooms) => ({
  type: ADD_FAVORITES,
  rooms,
});

export const addToFavs = (room) => ({
  type: ADD_TO_FAVORITES,
  room,
});

export const removeFavs = (room) => ({
  type: REMOVE_FROM_FAVORITES,
  room,
});
