import clsx from "clsx";
import React from "react";
import { BiHome, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { UserType } from "utils";

const ProfileInfor = ({ account }) => {
  const navigate = useNavigate();
  const isTenant = account.type === UserType.TENANT;
  const addHomeButtonClasses =
    "cursor-pointer text-white p-3 rounded-md flex bg-[#7CB73F] items-center hover:bg-[#7CB73F90]";
  return (
    <div
      className={clsx("profile-banner-container", {
        tenant: isTenant,
        landlord: !isTenant,
      })}
    >
      <div className="profile-summary-container">
        <div className="flex items-center pl-[10px]">
          <div
            className="profile-picture"
            style={{ backgroundImage: `url(${account.profilePicture})` }}
          />
          <div className="user-details-summary-container">
            <div className="lg:text-base text-sm capitalize font-semibold ">
              {account?.firstName} {account.lastName}
            </div>
            <div className="lg:text-sm text-xs text-[#6a6a6a] font-medium lg:mb-1">
              {account.email}
            </div>
            <div className="text-xs font-semibold capitalize text-[#7CB73F]">
              {account.type}
            </div>
          </div>
        </div>
        <div className="flex items-center mt-5 pr-2">
          {!isTenant && (
            <div
              onClick={() => navigate("/properties/add-property")}
              className={addHomeButtonClasses}
            >
              <BiPlus className="add-home-icon plus" />
              <BiHome size={20} className="add-home-icon home" />

              <div className="add-home-label font-semibold">
                Add new property
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileInfor;
