import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Input } from "components";
import { MUTATION } from "graphql-actions";
import Form from "../../../../components/Form";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextContainer, TextLink } from "./emailReset.styles";

const EmailReset = ({ email, onChange, onComplete }) => {
  const [error, setError] = useState(null);
  const [forgotPassword, { data, loading }] = useMutation(
    MUTATION.AUTH.FORGOT_PASSWORD,
    {
      onError: (err) => setError(err),
    }
  );

  const formik = useFormik({
    validationSchema: yup.object({
      email: yup.string().required("Email is a required field.").nullable(),
    }),
    onSubmit: ({ email }) => {
      forgotPassword({ variables: { email } });
      onChange(email);
      setError(null);
    },
    initialValues: {
      email: null,
    },
    enableReinitialize: true,
  });

  return (
    <Form formik={formik}>
      <div style={{ marginTop: 40 }}>
        <Alert
          error={error}
          isShow={data?.forgotPassword}
          message={
            "Please check your email and click on the provided link to reset your password."
          }
        />
        <div>
          <Input.TextField name="email" label="Email address" />
        </div>
        <Button
          className="primary full-width"
          title="Reset Password"
          type="submit"
          loading={loading}
        />
        <TextContainer>
          Go back to{" "}
          <Link to="/login">
            <TextLink>Login page</TextLink>
          </Link>
        </TextContainer>
      </div>
    </Form>
  );
};

export default EmailReset;
