import React, { useState } from "react";
import { Footer, NavigationBar, Button, Stepper, Form } from "components";
import RoomDetails from "./roomDetails";
import { useNavigate, useParams } from "react-router-dom";
import { StyledPageContainer } from "./addProperty.styles";
import RoomImages from "./roomImages";
import RoomAddress from "./roomAddress";
import { useFormik } from "formik";
import { useValidationSchema } from "./hook/use-validation-schema";
import { useInitialValues } from "./hook/use-initial-values";
import { useGetProperty } from "./hook/use-property";
import RoomConfirmation from "./roomConfirmation";
import { useMutation } from "@apollo/client";
import { ADD_ROOM, UPDATE_ROOM } from "graphql-actions/mutation/rooms";
import { formatter, fileUploader, formValidators } from "utils";

const steps = [
  { label: "Details" },
  { label: "Location" },
  { label: "Images" },
  { label: "Confirm Details" },
];

const AddProperty = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [uploadingImages, setUploadingImages] = useState(false);
  const { data, loading: gettingRoom } = useGetProperty(params.id);

  const [addRoom, { loading }] = useMutation(ADD_ROOM, {
    onError: (error) => console.log(error.message),
    onCompleted: (data) => {
      return navigate(formatter.propertyUrl(data?.addRoom), {
        state: { property: data?.addRoom },
      });
    },
  });

  const [updateRoom, { loading: updating }] = useMutation(UPDATE_ROOM, {
    onError: (error) => console.log(error.message),
    onCompleted: (data) => {
      return navigate(formatter.propertyUrl(data?.updateRoom), {
        state: { property: data?.updateRoom },
      });
    },
  });

  const validationSchema = useValidationSchema(activeStep, data?.getRoom);
  const initialValues = useInitialValues(data?.getRoom);
  const formik = useFormik({
    validationSchema,
    onSubmit: (values) => onNext(values),
    initialValues,
    enableReinitialize: true,
  });

  const activeComponent = () => {
    switch (activeStep) {
      case 0:
        return <RoomDetails />;
      case 1:
        return <RoomAddress />;
      case 2:
        return <RoomImages />;
      case 3:
        return <RoomConfirmation onEdit={(step) => setActiveStep(step)} />;
      default:
        break;
    }
  };

  const onNext = async (values) => {
    if (activeStep < 3) {
      const isComplete = completedSteps.find((x) => x === activeStep);
      if (!isComplete) {
        setCompletedSteps([...completedSteps, activeStep.toString()]);
      }
      window.scrollTo(0, 0);
      return setActiveStep(activeStep + 1);
    }

    const images = await Promise.all(
      values.images.map(async (image) => {
        if (!formValidators.validateUrl(image)) {
          setUploadingImages(true);
          return await fileUploader(image);
        }
        return image;
      })
    );
    setUploadingImages(false);
    if (images.includes(undefined)) {
      return alert("Something went wrong while uploading your images");
    }

    if (data) {
      const _id = values._id;
      delete values._id;
      return await updateRoom({
        variables: {
          _id,
          input: { ...values, images },
        },
      });
    }
    return await addRoom({
      variables: {
        input: { ...values, images },
      },
    });
  };

  const isLoading =
    (gettingRoom && params.id) || formik.isSubmitting || loading || updating;
  return (
    <div>
      <NavigationBar />
      <div style={{ padding: "120px 0px 100px" }}>
        <StyledPageContainer>
          <h1 className="text-black lg:mb-[3.8rem] text-3xl">
            Add New Property
          </h1>
          <div className="max-md:hidden">
            <Stepper
              activeStep={activeStep}
              onClick={setActiveStep}
              completedSteps={completedSteps}
              steps={steps}
            />
          </div>
          <Form formik={formik} className="w-full px-5 rounded-md lg:w-[650px]">
            <div style={{ paddingTop: 40 }}>{activeComponent()}</div>
            <div
              className="max-md:px-4"
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                className="button primary w-full max-w-sm"
                type="submit"
                title={
                  uploadingImages
                    ? "Uploading your images"
                    : isLoading && activeStep === 3 && data
                    ? "Updating your property"
                    : isLoading && activeStep === 3
                    ? "Adding your property"
                    : data && activeStep === 3
                    ? "Update property"
                    : activeStep === 3
                    ? "Done"
                    : "Next"
                }
                disabled={activeStep > 4}
                loading={isLoading || uploadingImages}
              />
              {!(isLoading || uploadingImages) && (
                <Button
                  className="button no-border"
                  type="submit"
                  title={"Cancel"}
                  onClick={() => navigate(-1)}
                />
              )}
            </div>
          </Form>
        </StyledPageContainer>
      </div>
      <Footer />
    </div>
  );
};

export default AddProperty;
