import { Input } from "components";
import { useFormikContext } from "formik";
import moment from "moment";
import React from "react";
import { RowComponentStyle } from "./roomDetails.styles";
import { RentalType } from "utils";

const RoomDetails = () => {
  const { getFieldMeta } = useFormikContext();
  const meta = getFieldMeta("availability");

  return (
    <div className="w-full px-5 rounded-md lg:w-[650px]">
      <RowComponentStyle>
        <div style={{ width: "48%" }}>
          <Input.TextField type="number" name="rent" label="Rent" />
        </div>
        <div style={{ width: "48%" }}>
          <Input.TextField type="number" name="deposit" label="Deposit" />
        </div>
      </RowComponentStyle>
      <RowComponentStyle>
        <div className="lg:flex w-full md:justify-between">
          <div className="mb-3 flex w-full">
            <Input.RadioButton
              className="max-md:w-[50%] mr-4"
              name="meterbox"
              label="Meterbox"
              options={[true, false]}
            />
            <Input.RadioButton
              className="max-md:w-[50%]"
              name="parking"
              label="Parking"
              options={[true, false]}
            />
          </div>
          <div className="mb-3 flex lg:justify-evenly w-full">
            <Input.RadioButton
              className="max-md:w-[50%] max-md:mr-4"
              name="kids"
              label="Kids allowed?"
              options={[true, false]}
            />
            <Input.RadioButton
              className="max-md:w-[50%]"
              name="pets"
              label="Do you have pets?"
              options={[true, false]}
            />
          </div>
        </div>
      </RowComponentStyle>
      <RowComponentStyle>
        <div className="w-[32%] max-md:w-[48%] max-md:mr-1">
          <Input.Select
            name="rentalType"
            label="Rental Type"
            options={[
              ...Object.keys(RentalType).map(
                (key) =>
                  RentalType[key].charAt(0).toUpperCase() +
                  RentalType[key].slice(1)
              ),
            ]}
          />
        </div>
        <div className="w-[32%] max-md:w-6/12 min-h-10">
          <Input.Select
            name="bathroomType"
            label="Bathroom Type"
            options={["Shared", "Insuite", "Private", "None"]}
          />
        </div>
        <div className="w-[32%] max-md:w-full">
          <Input.TextField
            type="date"
            name="availability"
            label="Availability"
            min={meta.value ?? moment(new Date()).format("YYYY-MM-DD")}
          />
        </div>
      </RowComponentStyle>
      <RowComponentStyle>
        <div style={{ width: "100%" }}>
          <Input.TextField
            type="textarea"
            name="description"
            label="Please describe your rental to us."
            placeholder="This is where you give all the infor about your place..."
          />
        </div>
      </RowComponentStyle>
    </div>
  );
};

export default RoomDetails;
