import React from "react";
import { BiChevronRight } from "react-icons/bi";

import GoogleMapReact from "google-map-react";
import { formatter } from "utils";
import { useNavigate } from "react-router-dom";

const AnyReactComponent = () => (
  <div
    style={{
      height: 13,
      width: 13,
      borderRadius: 15,
      margin: "auto",
      backgroundColor: "#FC9E61",
    }}
  />
);

const RoomMap = ({ address, onSimilarListing }) => {
  const nav = useNavigate();
  const isProduction = window.location.hostname
    .toLowerCase()
    .includes("platform");
  if (!address?.geo) return null;
  const { township, city } = formatter.propertyAddress(address?.fullAddress);
  return (
    <section>
      <div>
        <h2 className="text-xl font-bold my-4">Map</h2>
        <div
          style={{
            height: 300,
            width: "100%",
            borderRadius: 5,
            overflow: "hidden",
            marginTop: 30,
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
            defaultCenter={{
              lat: Number?.(address?.geo?.lat),
              lng: Number?.(address?.geo?.lng),
            }}
            defaultZoom={15}
            draggable={false}
            yesIWantToUseGoogleMapApiInternals
          >
            <AnyReactComponent
              lat={Number?.(address?.geo?.lat)}
              lng={Number?.(address?.geo?.lng)}
            />
          </GoogleMapReact>
        </div>
        {!isProduction && (
          <div
            onClick={onSimilarListing}
            style={{
              cursor: "pointer",
              fontSize: 16,
              color: "#7DB73E",
              fontWeight: "600",
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
              alignItems: "center",
            }}
          >
            <div onClick={() => nav(`/properties/${city}/${township}`)}>
              See more listings in {township}
            </div>
            <BiChevronRight size={20} style={{ marginTop: 3, marginLeft: 5 }} />
          </div>
        )}
      </div>
      <hr style={{ margin: "40px 0px", borderColor: "#6a6a6a15" }} />
      <p
        style={{
          color: "#6a6a6a",
          fontSize: 13,
          fontWeight: 500,
          marginBottom: 40,
        }}
      >
        You agree to Lokshin Rentals' Terms of Use & Privacy Policy, By choosing
        to contact a property, you also agree that Lokshin Rentals Group,
        landlords, and property managers may call or text you about any
        inquiries you submit through our services, which may involve use of
        automated means and prerecorded/artificial voices. You don't need to
        consent as a condition of renting any property. Message/data rates may
        apply.
      </p>
    </section>
  );
};

export default RoomMap;
