import { gql } from "@apollo/client";

export const SIGN_IN_USER = gql`
  query ($email: String!, $password: String!) {
    signinUser(email: $email, password: $password) {
      token
    }
  }
`;

export const IS_TOKEN_VALID = gql`
  query isTokenValid {
    isTokenValid
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      _id
      firstName
      lastName
      email
      phoneNumber
      whatsappNumber
      type
      profilePicture
      status
      createdDate
    }
  }
`;
