import React from "react";
import Autocomplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import { useFormikContext } from "formik";
import { clsx } from "clsx";

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      border: "1px solid #000",
      height: 10,
      width: 10,
      borderRadius: 15,
      display: "flex",
    }}
  >
    <div
      style={{
        height: 7,
        width: 7,
        borderRadius: 15,
        margin: "auto",
        backgroundColor: "#7CB63E",
      }}
    />
  </div>
);

const RoomAddress = () => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const meta = getFieldMeta("address");
  const [geo, setGeo] = React.useState(meta.value?.geo || null);
  const [fullAddress, setFullAddress] = React.useState(meta.value.fullAddress);

  React.useEffect(() => {
    setFullAddress(meta.value.fullAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHander = (place) => {
    setGeo({
      lat: place.geometry.location?.lat(),
      lng: place.geometry.location?.lng(),
    });
    setFieldValue("address", {
      placeId: place.place_id,
      fullAddress: place.formatted_address,
      geo: {
        lat: place.geometry.location?.lat(),
        lng: place.geometry.location?.lng(),
      },
    });
  };

  return (
    <div className="w-full px-5 rounded-md lg:w-[650px] mb-7">
      <div
        style={{
          marginBottom: 5,
          fontSize: 12,
          color: "#6a6a6a",
          fontWeight: 500,
        }}
      >
        Full Address
      </div>

      <Autocomplete
        className={clsx(
          "custom-input border-[#e5e5e5] !max-w-[650px] !w-full",
          {
            "border-red-600": meta.touched && meta.error,
          }
        )}
        apiKey={process.env.REACT_APP_MAP_KEY}
        onPlaceSelected={onChangeHander}
        types={["geocode"]}
        defaultValue={fullAddress}
        options={{
          types: ["geocode"],
          fields: [
            "address_components",
            "geometry.location",
            "place_id",
            "formatted_address",
          ],
          componentRestrictions: { country: "za" },
        }}
      />
      <div className="input-error">{meta.error?.fullAddress}</div>
      {geo?.lat && (
        <div className="mt-4" style={{ height: 300, width: "100%" }}>
          <GoogleMapReact
            defaultCenter={geo}
            defaultZoom={15}
            draggable={false}
            zoom={false}
          >
            <AnyReactComponent lat={geo?.lat} lng={geo?.lng} />
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default RoomAddress;
