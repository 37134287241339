import React from "react";
import { BiCalendar, BiCheckDouble, BiUser } from "react-icons/bi";
import { clsx } from "clsx";
import { IoClose, IoCloseCircleOutline } from "react-icons/io5";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoMdLocate } from "react-icons/io";
import Button from "../button";
import { UserType } from "utils";

const RequestCard = ({ request, account }) => {
  const defaultClasses =
    "flex-row flex items-center justify-between  p-2 text-[10px] text-white capitalize font-semibold";
  const user = account.type === UserType.TENANT ? "requestee" : "requester";
  const conditionalClasses = {
    "!bg-orange-500": request.status === "PENDING",
    "!bg-[#7CB73F] text-white": request.status === "ACCEPTED",
    "!bg-[#dd0000] text-white": request.status === "REJECTED",
  };
  const StatusIcon =
    request.status === "ACCEPTED"
      ? BiCheckDouble
      : request.status === "REJECTED"
      ? IoCloseCircleOutline
      : RiErrorWarningLine;
  return (
    <div className="w-full lg:w-[30%] rounded-md overflow-hidden lg:mr-4 mb-4 border border-neutral-100">
      <div
        class={clsx(
          defaultClasses,
          // "p-3 flex-row flex items-center justify-between",
          conditionalClasses
        )}
      >
        <StatusIcon size={20} className={"mr-1"} />
        <span> {request.status}</span>
        <IoClose
          size={20}
          className={clsx("mr-1", {
            invisible: request.status !== "PENDING",
          })}
        />
      </div>
      <img
        className="w-full h-[140px] object-cover"
        src={request?.room?.images[0]}
        alt="Sunset in the mountains"
      />
      <div className="px-4 py-4 pb-1">
        <div className="flex-row flex border-b-[1px] items-start">
          <BiUser className="mr-2 cursor-pointer text-gray-500" />
          <div className="ml-2 mb-2 -mt-1">
            <div className="font-bold text-sm -mb-1">
              {request?.[`${user}`]?.firstName}
            </div>
            <div className="text-xs">{request?.[`${user}`]?.email}</div>
          </div>
        </div>
        <div className="flex-row flex border-b-[1px] items-start py-3">
          <BiCalendar className="mr-2 cursor-pointer text-gray-500" />
          <div className="ml-2 text-xs font-medium">{request.viewDate}</div>
        </div>
        <div className="flex-row flex border-b-[1px] items-start py-3">
          <IoMdLocate className="mr-2 cursor-pointer text-gray-500" />
          <div className="ml-2 text-xs font-medium">{request.requestType}</div>
        </div>
        <div className="py-3 text-center ">
          {request.status === "PENDING" && (
            <Button
              className="small primary w-full cursor-pointer mb-4"
              title="Accept"
            />
          )}
          <div className="text-xs  text-gray-500 cursor-pointer">More info</div>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
