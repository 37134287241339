import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers'
import loggerMiddleware from 'redux-logger';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
const persistConfig = {
  key: 'root',
  storage,
}
 
const persistedReducer = persistReducer(persistConfig, reducers)
const logger = window.location.href.includes("local") ? applyMiddleware(loggerMiddleware) : applyMiddleware()

const store = createStore(persistedReducer,logger)
let persistor = persistStore(store)

export { store, persistor }