import React from "react";
import { BiBath, BiEdit, BiTrash } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa";
import { MdBed } from "react-icons/md";
import { TbBolt } from "react-icons/tb";
import { UserType, formatter } from "../../utils";
import { FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { MUTATION } from "graphql-actions";
import { useMutation } from "@apollo/client";
import { favouritesActions } from "../../store/actions";
import { bindActionCreators } from "redux";
import { clsx } from "clsx";
import { TailSpin } from "react-loader-spinner";
import { useState } from "react";
import { Modals } from "..";
import PropertyCardRibbon from "../propertyCardRibbon/index";
import { useFormikContext } from "formik";

const imagePlaceholder =
  "https://devtools360.com/assets/img/site-placeholder.jpg";

const PropertyCard = ({
  property,
  account,
  favourites,
  favouritesActions,
  onPropertyCard,
  onPropertyUpdated,
  isAccountPage,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const form = useFormikContext("delete-form");
  console.log("values", form);
  const image =
    property?.images?.[0] === null ? imagePlaceholder : property?.images?.[0];
  const Icon = 1 === 2 - 1 ? FaHeart : FaRegHeart;
  const township = property?.address?.fullAddress?.split(", ")?.[1];

  const [updateRoom, { loading: updating }] = useMutation(
    MUTATION.ROOM.UPDATE_ROOM,
    {
      refetchQueries: "getAllRooms",
      onError: (error) => console.log(error.message),
      onCompleted: (data) => {
        setIsOpen(false);
        onPropertyUpdated();
      },
    }
  );

  const [addToFavs, { loading }] = useMutation(
    MUTATION.ROOM.UPDATE_FAVOURITE_ROOMS,
    {
      refetchQueries: "currentUser",
      onCompleted: (data) => {
        const isFav = data.updateFavouriteRoom.room.find(
          (x) => x._id === property._id
        );
        if (isFav) {
          return favouritesActions.addToFavs(property);
        }
        return favouritesActions.removeFavs(property);
      },
    }
  );

  const onProperty = () => {
    navigate(formatter.propertyUrl(property), { state: { property } });
  };

  const onPropertyDelete = async (value) => {
    const status = formatter.getRentalStatus(value);
    return await updateRoom({
      variables: {
        _id: property._id,
        input: { active: false, status },
      },
    });
  };

  const onPropertyEdit = (id) =>
    navigate(`/properties/edit-property/${property._id}`);

  const checkIfFavourite = () =>
    favourites.find((x) => x._id === property._id) ? "#7CB73F" : "#d0d0d0";

  const isOwner = property?.user?._id === account._id;
  const isLoggedIn = account?.type === UserType.LANDLORD && isOwner;

  return (
    <div className="property-card-container rounded">
      <Modals.DeleteModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={onPropertyDelete}
        loading={updating}
      />

      <div className="property-card rounded">
        {isLoggedIn && (
          <div className="pl-5 pr-3 py-3 flex-row flex bg-white items-end justify-end absolute right-0 rounded-bl">
            <BiEdit
              onClick={onPropertyEdit}
              className="mr-4 cursor-pointer text-[#7CB73F]"
            />
            {!updating ? (
              <BiTrash
                onClick={() => setIsOpen(true)}
                className="cursor-pointer text-red-500"
              />
            ) : (
              <TailSpin
                height={10}
                width={10}
                wrapperStyle={{ margin: "auto" }}
                secondaryColor="#7CB73F"
              />
            )}
          </div>
        )}
        <img
          className={clsx("property-card-image")}
          alt={township + "-property-image"}
          src={image}
          onClick={onPropertyCard ? onPropertyCard : onProperty}
        />
        {property?.deposit === 0 && <PropertyCardRibbon title={"No deposit"} />}
        <div className="property-card-body">
          {!isOwner && (
            <div
              onClick={() =>
                loading ? null : addToFavs({ variables: { _id: property._id } })
              }
              className={clsx("property-card-favourite", {
                "cursor-not-allowed": loading,
              })}
            >
              {!loading && (
                <Icon
                  size={17}
                  color={checkIfFavourite()}
                  style={{ margin: "auto" }}
                />
              )}
              {loading && (
                <TailSpin
                  height={15}
                  width={15}
                  wrapperStyle={{ margin: "auto" }}
                  secondaryColor="#7CB73F"
                />
              )}
            </div>
          )}
          <div className="property-card-rent-container pt-2">
            <div className="property-card-rent-value">
              {formatter.toRand(property?.rent)}
            </div>
            <div className="property-card-rent-text">/month</div>
          </div>
          <h3 className="property-card-location mt-2">{township}</h3>
          <p className="property-card-address line-clamp-2">
            {property?.address?.fullAddress}
          </p>
          <div
            className="pt-3 pb-1 mt-3 border-t-[1px]"
            onClick={onPropertyCard ? onPropertyCard : onProperty}
          >
            <div
              className="property-card-features-container"
              onClick={onPropertyCard ? onPropertyCard : onProperty}
            >
              <div className="property-card-features-item">
                <MdBed color="#7CB73F" />
                <div className="property-card-features-item-title">
                  {property?.rentalType}
                </div>
              </div>
              <div className="property-card-features-item">
                <BiBath color="#7CB73F" />
                <div className="property-card-features-item-title">
                  {property?.bathroomType}
                </div>
              </div>
              <div className="property-card-features-item">
                <TbBolt color="#7CB73F" />
                <div className="property-card-features-item-title">
                  {property?.meterbox ? "Meter" : "No Meter"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  favourites: state.favourites,
});

const mapDispatchToProps = (dispatch) => ({
  favouritesActions: bindActionCreators(favouritesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
