import React from "react";
import { PropertyCard } from "components";

const SimilarListing = ({ data }) => {
  if (!data?.length) return null;
  return (
    <div className="lg:pt-16 pb-10 bg-[#F7FCF7] max-w-full max-md:p-2">
      <div className="mt-0 mx-auto lg:w-[1000px]">
        <section>
          <h2 className="text-2xl font-bold mt-5 max-md:pl-3">
            Similar listings
          </h2>
          <div className="flex flex-row flex-wrap mt-10">
            {data.map((x, index) => (
              <PropertyCard key={index} property={x} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SimilarListing;
