import styled from "styled-components";

export const StyledImages = styled.img`
  width: ${550 / 3}px;
  height: 185px;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
`;

export const StyledLabel = styled.div`
  margin-right: 10px;
  min-width: 30%;
  font-weight: 800;
  margin-bottom: 10px;
`;
