import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./auth";
import { useQuery } from "@apollo/client";
import { QUERY } from "graphql-actions";
import { store } from "../../store";
import { ProtectedRoutes } from "../../routes";

export const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const nav = useNavigate();
  const s = useLocation();
  const { account } = store.getState();
  const { data, loading } = useQuery(QUERY.USER.IS_TOKEN_VALID);

  useEffect(() => {
    if (!data?.isTokenValid && !loading) {
      auth.logout();
    }
  }, [auth, data, loading, nav]);

  if (!auth.localToken) {
    return nav("/properties");
  } else {
    const route = ProtectedRoutes.find((x) => x.path === s.pathname);
    if (route && !(account.type === route.type || route.type === "all")) {
      return nav("/account");
    }
    return children;
  }
};
