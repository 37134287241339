import React from "react";
import { CgFileDocument } from "react-icons/cg";
import { formatter } from "../../../../utils";
import RequestForm from "./requestFom";

import moment from "moment";
import Form from "components/Form";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { MUTATION } from "graphql-actions";
import { clsx } from "clsx";
import { Button } from "components";
import { UserType } from "utils";

const RentCard = ({ account, onApply, room }) => {
  let today = new Date();
  today.setDate(today.getDate() + 1);
  const tomorrow = moment(today).format("YYYY-MM-DD");
  const formik = useFormik({
    onSubmit: (values) => {
      createRequest({
        variables: {
          input: {
            room: room._id,
            ...values,
          },
        },
      });
    },
    initialValues: {
      requestType: "VIRTUAL",
      viewDate: tomorrow,
      message: "",
    },
  });
  const [createRequest, { loading }] = useMutation(
    MUTATION.REQUEST.CREATE_REQUEST,
    {
      onCompleted: () => {
        alert("request added");
        formik.setSubmitting(false);
      },
      onError: (error) => {
        alert(error.message);
        formik.setSubmitting(false);
      },
    }
  );
  const isOwner = account?.type === UserType.LANDLORD || !account._id;
  const isProduction = window.location.hostname
    .toLowerCase()
    .includes("platform");
  return (
    <div className="p-5 rounded border-[#e9e9e9] border">
      <p className="m-0 text-xs text-[#6a6a6a] font-medium">Rent price</p>
      <div className="flex mt-2">
        <div className="text-[#7CB73F] font-extrabold text-xl">
          {formatter.toRand(room?.rent)}
        </div>
        <div className="mt-2 ml-1 text-xs text-[#6a6a6a] font-medium">
          /month
        </div>
      </div>
      {room?.deposit > 0 && (
        <div className="flex text-sm">
          <span className=" font-extrabold  mr-1">Deposit -</span>
          <span className="text-[#7CB73F] font-extrabold">
            {formatter.toRand(room?.deposit)}
          </span>
        </div>
      )}
      {!isProduction && (
        <Button
          className={clsx("primary full-width !px-3 !py-2 mt-3", {
            hidden: isOwner,
          })}
          leftIcon={<CgFileDocument />}
          title={"Apply now"}
          onClick={null}
        />
      )}
      {!isProduction && (
        <hr
          className={clsx("mt-5 mb-2 border border-[#6a6a6a15]", {
            hidden: isOwner,
          })}
        />
      )}

      {!isOwner && !isProduction && (
        <Form formik={formik} name="request-form">
          <RequestForm loading={loading || formik.isSubmitting} />
        </Form>
      )}
    </div>
  );
};

export default RentCard;
