import * as yup from "yup";

export const useValidationSchema = () => {
  const requiredMessage = "Please fill in the required field.";

  return yup.object().shape(
    {
      email: yup.string().when("whatsappNumber", {
        is: (whatsappNumber) => !whatsappNumber || whatsappNumber.length === 0,
        then: yup.string().required(requiredMessage).nullable(),
      }),
      password: yup.string().required(requiredMessage).nullable(),
      confirmPassword: yup
        .string()
        .test("confirmPassword", "Passwords must match", function (value) {
          return this.parent.password === value || this.parent.confirmPassword;
        })
        .required(requiredMessage)
        .nullable(),
      phoneNumber: yup.string().required(requiredMessage).nullable(),
      whatsappNumber: yup.string().when("email", {
        is: (email) => !email || email.length === 0,
        then: yup.string().required(requiredMessage).nullable(),
      }),
      firstName: yup.string().required(requiredMessage).nullable(),
      lastName: yup.string().required(requiredMessage).nullable(),
      type: yup.string().required(requiredMessage).nullable(),
    },
    ["email", "whatsappNumber"]
  );
};
