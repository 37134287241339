import React from "react";
import { useFormikContext } from "formik";
import clsx from "clsx";
import { UserType } from "utils";

const UserTypeSelect = ({ name, Icon, className }) => {
  const { values, setFieldValue } = useFormikContext();
  const useTypeClass =
    "bg-[#7CB73F20] flex w-9 h-9 rounded-full justify-center items-center";

  const isActive = values.type === name.toLowerCase();
  return (
    <div
      onClick={() => setFieldValue("type", name.toLowerCase())}
      className={clsx("cursor-pointer flex flex-row items-center", {
        "mr-10": name.toLowerCase() !== UserType.TENANT,
      })}
    >
      <div
        className={clsx(useTypeClass, {
          "!bg-[#7CB73F]": isActive,
        })}
      >
        <Icon size={20} style={{ color: isActive ? "#fff" : "#7CB73F" }} />
      </div>
      <div
        className={clsx(" text-sm font-medium text-[#212121] ml-3", {
          "!text[#6a6a6a]": !isActive,
          "!font-semibold": isActive,
        })}
      >
        {name}
      </div>
    </div>
  );
};

export default UserTypeSelect;
