import styled from "styled-components";

export const MainFormComponentStyle = styled.div`
  border-radius: 5;
  padding: 0px 20px;
  box-sizing: border-box;
  max-width: 100vw;
`;

export const StyledPageContainer = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 0px auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;
