import React from "react";
import { RiWhatsappFill } from "react-icons/ri";
import { Button } from "components";
import { FaEnvelope } from "react-icons/fa";

const RoomDescription = ({ data }) => {
  const onWhatsapp = () => {
    const message = encodeURIComponent(
      `I am interested in renting the property listed on Lokshin Rentals at ${window.location.href}. Could you please inform me about its availability and when I could arrange a viewing?`
    );
    let number = data?.user?.whatsappNumber ?? data?.user?.phoneNumber;
    if (number && number?.startsWith("0")) {
      number = number.replace("0", "27");
      return window.open(`https://wa.me/${number}?text=${message}`);
    }
    return window.open(`https://wa.me/+27727926932?text=${message}`);
  };

  return (
    <section>
      <div className="mb-7">
        <h1 className="text-2xl font-bold my-4">
          About this <span className="text-lime-500">{data?.rentalType}</span>
        </h1>
        <div
          className="font-[Plus Jakarta Sans, sans-serif] text-sm text-[#6a6a6a]"
          dangerouslySetInnerHTML={{
            __html: data?.description.replace(/\n/g, "<br />"),
          }}
        />
      </div>

      <div className="mb-5 bg-[#F7FCF7] rounded-md p-5 border-2 border-[#D9EDD9]">
        <p className="m-0 text-[#6a6a6a] text-sm font-semibold">
          Listed by property owner
        </p>
        <div className="lg:flex mt-5 justify-between items-end">
          <div className="flex items-center max-md:mb-3">
            <img
              alt="user-profile"
              src={data?.user?.profilePicture}
              className="w-14 h-14 rounded-full mr-2 object-cover"
            />
            <div>
              <div className="text-sm font-semibold capitalize">
                {data?.user?.firstName} {data?.user?.lastName}
              </div>
              {data?.user?.email && (
                <div className="lg:mb-1 text-sm font-semibold lowecase text-neutral-400">
                  {data.user.email}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {data?.user?.email && (
              <Button
                className="small bg-[#7CB73F20] text-[#7cb73f] no-border"
                title="Email"
                leftIcon={<FaEnvelope color="#7DB73E" />}
                onClick={() => window.open(`mailto:${data?.user?.email}`)}
              />
            )}
            <Button
              className="small bg-[#7CB73F20] text-[#7cb73f] no-border ml-2"
              title="Whatsapp"
              leftIcon={<RiWhatsappFill color="#7DB73E" />}
              onClick={onWhatsapp}
            />
          </div>
        </div>
      </div>

      <hr className="my-10 border-[#6a6a6a15]" />
    </section>
  );
};

export default RoomDescription;
