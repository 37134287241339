import styled from "styled-components";

export const TextContainer = styled.div`
  text-align: center;
  font-size: 13px;
  color: #6a6a6a;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const TextLink = styled.span`
  color: #212121;
  font-size: 13px;
  font-weight: 600;
`;
