import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/authContext/auth";
import { NavigationBar, Alert, Button, Footer, Input, Form } from "components";
import { QUERY } from "graphql-actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { accountActions, authActions } from "../../../store/actions";
import { useFormik } from "formik";
import { useValidationSchema } from "./hook/use-validation-schema";
import ReactGA from "react-ga";

const Login = ({ acountActions, authActions }) => {
  const [login, { data, loading, error }] = useLazyQuery(
    QUERY.USER.SIGN_IN_USER
  );
  const nav = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (auth.localToken) {
      return nav("/properties", { replace: true });
    }
  }, [auth.localToken, nav]);

  useEffect(() => {
    // return nav("/properties")
    if (data?.signinUser?.token) {
      authActions.addToken(data.signinUser.token);
      auth.login(data.signinUser.token);
      ReactGA.event({
        category: "User",
        action: "Logged In",
      });
      const url = location.state ?? "/account";
      return nav(url, { replace: true });
    }
  }, [acountActions, auth, authActions, data, error, location.state, nav]);

  const validationSchema = useValidationSchema();
  const formik = useFormik({
    validationSchema,
    initialValues: { email: "", password: "" },
    onSubmit: (values) => login({ variables: { ...values } }),
  });

  return (
    <Form formik={formik}>
      <NavigationBar />
      <section className="flex flex-col pt-44 pb-14">
        <div style={{ maxWidth: 1000, margin: "auto" }}>
          <div className="max-w-[100vw] w-[600px] px-10 box-border text-[#6a6a6a] rounded-md font-medium">
            <div className="text-center text-[#212121] text-3xl font-semibold mb-5">
              Login
            </div>
            <div className="text-center text-[#6a6a6a] text-base font-medium">
              Hey, <strong>Welcome back.</strong> Please enter your details to
              log in.
            </div>
            <div style={{ marginTop: 40 }}>
              <Alert error={error} />
              <Input.TextField
                label="Email address or Phone number"
                name="email"
                type="text"
              />
              <Input.Password label="Password" name="password" />

              <Link className="link" to="/forgot-password">
                <div className="text-xs text-[#6a6a6a] mb-10 -mt-2 font-medium">
                  Having trouble in signing in?
                </div>
              </Link>

              <Button
                className="primary full-width"
                title="Login"
                loading={loading}
                type="submit"
              />
              <div className="text-xs text-[#6a6a6a] my-10 font-medium text-center">
                Don't have an account?{" "}
                <Link to="/register">
                  <span className="text-xs text-[#212121] my-10 font-semibold">
                    Sign up
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer authFooter />
    </Form>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
