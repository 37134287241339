import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import moment from "moment";
import { TbHome } from "react-icons/tb";
import { Button, Input } from "components";
import { useFormikContext } from "formik";
import { CgPlayButton } from "react-icons/cg";
import clsx from "clsx";

const RequestForm = ({ loading }) => {
  const { values, setFieldValue } = useFormikContext();
  let today = new Date();
  today.setDate(today.getDate() + 1);
  const tomorrow = moment(today).format("YYYY-MM-DD");
  return (
    <div>
      <h4 className="font-bold my-4">Request a home tour</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 15,
        }}
      >
        <Button
          className={clsx("small w-6/12 mr-2", {
            primary: values.requestType !== "VIRTUAL",
          })}
          title="In Person"
          leftIcon={<TbHome />}
          onClick={() => setFieldValue("requestType", "IN PERSON")}
        />
        <Button
          className={clsx("small w-6/12 !p-1", {
            primary: values.requestType === "VIRTUAL",
          })}
          title="Virtual"
          leftIcon={<CgPlayButton size={18} scale="md" />}
          onClick={() => setFieldValue("requestType", "VIRTUAL")}
        />
      </div>
      <Input.TextField type="date" name="viewDate" min={tomorrow} />
      <Input.TextField
        type="textarea"
        name="message"
        placeholder="Leave the landlord a message"
        rows={3}
      />
      <Button
        loading={loading}
        className="full-width dark"
        title="Request a tour"
        leftIcon={<HiOutlineLocationMarker />}
        type="submit"
      />
      <p style={{ fontSize: 10, color: "#6a6a6a", marginTop: 25 }}>
        It's free, with no obligation -- cancel anytime.
      </p>
    </div>
  );
};

export default RequestForm;
