import React from "react";
import { BsStars } from "react-icons/bs";
import { clsx } from "clsx";

const PropertyCardRibbon = ({ variant = "", title = "Popular" }) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <div className={clsx(`tri ${variant}`)} />
        <div className={clsx(`square ${variant}`)}>
          <BsStars color="#fff" style={{ marginRight: 3 }} />
          {title}
        </div>
      </div>
    </div>
  );
};

export default PropertyCardRibbon;
