import moment from 'moment'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IoSearch } from 'react-icons/io5';
import { formatter } from '../../utils';

const SearchBar = ({
  title = "Search properties to rent",
  defaultValues,
  onSearchBar
}) => {
  // eslint-disable-next-line no-unused-vars
  const nav = useNavigate()
  const today = new Date();
  const state = useLocation();
  const [searchValues, setSearchValues] = useState({
    location:"",
    when: moment(today.setDate(today.getDay()+1)).format("YYYY-MM-DD"),
    price: 800,
    property_type: "single room"
  })

  useEffect(() => {
    setSearchValues(defaultValues);
  }, [defaultValues])

  useEffect(() => {
    const search = state.search
    if(search.includes("location")) {
      setSearchValues({...searchValues, location:formatter.searchSplitter(search,"location")})
    }
    if(search.includes("when")){
      setSearchValues({...searchValues, when:formatter.searchSplitter(search,"when")})
    }
    if(search.includes("price")) {
      setSearchValues({...searchValues, price:formatter.searchSplitter(search,"price")})
    }
    if(search.includes("property_type")) {
      setSearchValues({...searchValues, property_type:formatter.searchSplitter(search,"property_type")})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);
  

  const onSearch = () => {
    let url = "/search";
    if(searchValues?.location)url = url+ `?location=${searchValues.location}`
    if(searchValues?.when)  url = url+ `?when=${searchValues.when}`
    if(searchValues?.price) url = url + `?price=${searchValues.price}`
    if(searchValues?.property_type) url = url+ `?property_type=${searchValues.property_type}`
    return nav(url)
  }
    
  return (
    <section className='search-bar-container'>
    <div className="search-bar" style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end', marginBottom:20, position:'relative'}}>
        <h1 style={{fontSize:35, marginBottom:0}}>{title}</h1>
        {onSearchBar && <>
          <div className="search-bar-input-container" style={{position:'relative', width:'30%'}}>
            <IoSearch size={16} style={{position:'absolute',top:11,left:10, color:'#6a6a6a90'}}/>
            <input
                className="search-bar-input"
                style={{backgroundColor:'#fff',width:'100%',border:'2px solid #7CB73F30', borderRadius:5, padding:"8px 10px 8px 30px", fontSize:14, color:'#6a6a6a90', fontWeight:500}}
                type="text"
                placeholder='Search with Search Bar'
                onChange={(e) => onSearchBar(e.target.value)}
            />
          </div>
        </>}
    </div>
    <div className="advance-search-bar" style={{width:1000,backgroundColor:'#fff',display:'flex',padding:"20px 25px",justifyContent:'space-between', marginBottom:60, borderRadius:5, flexWrap:'wrap'}}>
      <div style={{width:'19%',borderRight:'1px solid #e7e7e7',marginRight:20 }}>
        <div style={{color:'#6a6a6a90', marginBottom:10, fontSize:14, fontWeight:600,textTransform:'unset'}}>Location</div>
        <input
            className='search-bar-input'
            type="type"
            placeholder='Township'
            value={searchValues?.location}
            onChange={(e) => setSearchValues({...searchValues, location:e.target.value})}
        />
      </div>

      <div style={{borderRight:'1px solid #e7e7e7',marginRight:20,width:'19%',}}>
        <div style={{color:'#6a6a6a90', marginBottom:10, fontSize:14, fontWeight:600}}>When</div>
        <input
          className='search-bar-input'
          type="date"
          min={moment(today.setDate(today.getDay()+1)).format("YYYY-MM-DD")}
          max={moment(new Date(today.getFullYear(), today.getMonth() + 1, 0)).format("YYYY-MM-DD")}
          value={searchValues?.when}
          onChange={(e) => setSearchValues({...searchValues, when:e.target.value})}
          style={{textTransform:'uppercase'}}
        />
      </div>

      <div style={{borderRight:'1px solid #e7e7e7',marginRight:20,width:'19%', }}>
        <div style={{color:'#6a6a6a90', marginBottom:10, fontSize:14, fontWeight:600}}>Max Price</div>
        <select 
          value={searchValues?.price} 
          className='search-bar-input'
          onChange={(e) => setSearchValues({...searchValues, price:e.target.value})}
        >
          <option value={800}>R600 - R800</option>
          <option value={1200} >R801 - R1200</option>
          <option value={1800}>R1201 - R1800</option>
          <option value={2500}>R1801 - R2500</option>
          <option value={4000} >R2501 - R4000</option>
          <option value={4001}>R4001+</option>
        </select>
      </div>

      <div style={{borderRight:'1px solid #e7e7e7',marginRight:20,width:'19%', }}>
        <div style={{color:'#6a6a6a90', marginBottom:10, fontSize:14, fontWeight:600}}>Property Type</div>
        <select 
          value={searchValues?.property_type} 
          className='search-bar-input'
          onChange={(e) => setSearchValues({...searchValues, property_type:e.target.value})}
        >
          <option value={"single room"}>Single Room</option>
          <option value={"two room"}>Two Room</option>
          <option value={"garage"}>Garage</option>
          <option value={"house"}>House</option>
        </select>
      </div>
     
      <div>
        <button 
            className='lg' 
            onClick={onSearch} 
            style={{backgroundColor:'#7CB73F', border:'1px solid #7DB73E', color:'#fff'}}
        >
          Search
        </button>
      </div>
    </div>
  </section>
  )
}

export default SearchBar