import Uploader from "components/input/upload";
import React, { useEffect, useState } from "react";
import { BiX } from "react-icons/bi";
import { useFormikContext } from "formik";
import {
  ImageDeleteButton,
  StyledImage,
  StyledUploadButtonContainer,
} from "./roomImages.styles";

const RoomImages = (props) => {
  const [images, setImages] = useState([]);
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const meta = getFieldMeta("images");

  useEffect(() => {
    setImages([...images, ...meta.value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = (index) => {
    setFieldValue("images", [...meta.value.filter((_x, i) => i !== index)]);
  };

  return (
    <div>
      <StyledUploadButtonContainer>
        <Uploader multiple limit={6} />
      </StyledUploadButtonContainer>
      <div className="flex flex-wrap">
        {meta.value.map((file, index) => (
          <div className="relative w-[45%] odd:mr-3 mb-5" key={index}>
            <ImageDeleteButton onClick={() => onDelete(index)}>
              <BiX size={20} color="#fff" />
            </ImageDeleteButton>
            <StyledImage alt="house" src={file} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoomImages;
