import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../assets/Lokshin.png";
import { clsx } from "clsx";

const Footer = ({ authFooter, noBorder = window.innerHeight > 769 }) => {
  const year = new Date().getFullYear();
  const textClass = "text-center text-[13px] text-[#212121] font-medium";
  const textLinkClass = "text-[13px] text-[#6a6a6a] font-medium mb-[5px]";
  const authFooterClass = "w-full py-[30px] border-t border-[#e9e9e9]";
  if (authFooter) {
    return (
      <div className={clsx(authFooterClass, { "!border-none": noBorder })}>
        <div className={textClass}>
          ©{year} Lokshin Rentals. All rights reserved
        </div>
        <div className="flex text-center text-[#6a6a6a] lg:justify-center justify-center mt-10">
          <FaFacebook
            className="mr-[30px] text-xl cursor-pointer"
            onClick={() =>
              window.open("https://www.facebook.com/lokshinrentals")
            }
          />
          <FaWhatsapp
            className="mr-[30px] text-xl cursor-pointer"
            onClick={() =>
              window.open(
                `https://wa.me/+27727926932?text=Hi, I'd like to sign up for lokshin rentals.`
              )
            }
          />
          <FaInstagram
            className=" text-xl cursor-pointer"
            onClick={() =>
              window.open("https://www.instagram.com/lokshinrentals")
            }
          />
        </div>
      </div>
    );
  }
  return (
    <footer className="bg-white w-full border-t border-[#E9E9E9]">
      <div className=" pt-[60px] pb-10">
        <div className="max-w-full w-[1200px] m-auto justify-center content-center">
          <div className="flex flex-wrap">
            <div className="w-[40%] max-md:w-full max-md:mb-5">
              <div className="flex items-center px-[10px]">
                <Link
                  to={"/"}
                  style={{ fontWeight: 600, fontSize: 14, color: "#212121" }}
                >
                  <img alt="logo" src={logo} className="w-[200px]" href="#" />
                </Link>
              </div>
            </div>
            <div className="w-[20%] p-[10px] max-md:w-1/2">
              <div>
                <p className="lg:text-sm text-xs font-bold uppercase mb-5">
                  Company
                </p>
                <a className="link" href="https://lokshinrentals.co.za/about/">
                  <div className={textLinkClass}>About</div>
                </a>
                <a
                  className="link"
                  href="https://lokshinrentals.co.za/about#issues-we-solving"
                >
                  <div className={textLinkClass}>Problem we solving</div>
                </a>
                {/* <a
                  className="link"
                  href="https://lokshinrentals.co.za/about#how-it-works"
                >
                  <div className={textLinkClass}>How it works</div>
                </a> */}
                <a className="link" href="https://lokshinrentals.co.za/contact">
                  <div className={textLinkClass}>Contact</div>
                </a>
              </div>
            </div>
            {/* <div className="w-[20%] p-[10px] max-md:w-1/2">
              <p className="lg:text-sm text-xs font-bold uppercase mb-5">
                PROPERTY MANAGEMENT
              </p>
              <a className="link" href="https://lokshinrentals.co.za/tenant">
                <div className={textLinkClass}>Tenant</div>
              </a>
              <a className="link" href="https://lokshinrentals.co.za/landlord">
                <div className={textLinkClass}>Landlord</div>
              </a>
            </div> */}
            <div className="w-[20%] p-[10px] max-md:w-1/2">
              <div>
                <p className="lg:text-sm text-xs font-bold uppercase mb-5">
                  Resources
                </p>
                <a className="link" href="https://lokshinrentals.co.za/blog/">
                  <div className={textLinkClass}>Blog</div>
                </a>
                <a
                  className="link"
                  href="https://lokshinrentals.co.za/about#faq"
                >
                  <div className={textLinkClass}>FAQ</div>
                </a>
                <a
                  className="link"
                  href="https://lokshinrentals.co.za/contact/"
                >
                  <div className={textLinkClass}>Help Center</div>
                </a>
              </div>
            </div>
            <div className="w-[20%] p-[10px] max-md:w-1/2">
              <div>
                <p className="lg:text-sm text-xs font-bold uppercase mb-5">
                  TERMS OF USE
                </p>
                <a className="link" href="https://lokshinrentals.co.za/tenant">
                  <div className={textLinkClass}>Trust & Safety</div>
                </a>
                <a
                  className="link"
                  href="https://lokshinrentals.co.za/term-of-service/"
                >
                  <div className={textLinkClass}>Terms of Service</div>
                </a>
                <a
                  className="link"
                  href="https://lokshinrentals.co.za/privacy-policy/"
                >
                  <div className={textLinkClass}>Privacy Policy</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-full py-5 px-0 border-t border-[#E9E9E9]">
        <div className="lg:w-[1200px] m-auto justify-center content-center w-full">
          <div className="lg:flex">
            <div className="lg:w-1/2 max-md:px-3 max-md:text-center">
              <span className="text-[13px] text-[#6a6a6a] font-medium">
                ©{year} Lokshin Rentals. Product of
                <a
                  style={{ color: "#7CB73F" }}
                  href="https://ohtech.co.za"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  OpenHouse Technology
                </a>
                .All rights reserved.
              </span>
            </div>
            <div className="lg:w-1/2 flex text-end text-[#6a6a6a] lg:justify-end max-md:pt-8 justify-center">
              <FaFacebook
                className="ml-[30px] text-xl cursor-pointer"
                onClick={() =>
                  window.open("https://www.facebook.com/lokshinrentals")
                }
              />
              <FaWhatsapp
                className="ml-[30px] text-xl cursor-pointer"
                onClick={() =>
                  window.open(
                    `https://wa.me/+27727926932?text=Hi, I'd like to sign up for lokshin rentals.`
                  )
                }
              />
              <FaInstagram
                className="ml-[30px] text-xl cursor-pointer"
                onClick={() =>
                  window.open("https://www.instagram.com/lokshinrentals")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
