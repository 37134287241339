
 const account = (state = {}, action)=> {
    switch(action.type){
        case 'ADD_ACCOUNT_DETAILS':
            return action.account
        case 'UPDATE_ACCOUNT_DETAILS':
            return {
                ...state,
                ...action.account
            }
        case 'REMOVE_ACCOUNT_DETAILS':
            return ""
        default:
            return state
    }
}

export default account;