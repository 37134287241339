import { useFormikContext } from "formik";
import React from "react";
import { BiImage } from "react-icons/bi";
import { UploadCustomButton } from "./upload.styles";

const Uploader = ({ name = "images", ...props }) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (props.multiple) {
      return convertMultipleFiles(event.target.files);
    }
    convertFile(event.target.files[0]);
  };

  const convertMultipleFiles = (files) => {
    files = Array.from(files);
    let urls = [];
    files.forEach((file, index) => {
      let reader = new FileReader();
      reader.onload = async (r) => {
        if (meta.value.length + urls.length < props.limit) {
          urls.push(r.target.result);
          return setFieldValue(name, [...urls, ...meta.value]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const convertFile = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (r) => {
      return setFieldValue(name, r.target.result);
    };
  };

  const disabled =
    props.disabled || (props.multiple && meta.value?.length === props.limit);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <>
        <UploadCustomButton
          onClick={handleClick}
          disabled={disabled}
          type="button"
          style={{
            border: `1px solid ${
              meta.error && meta.touched ? "red" : "#212121"
            }`,
          }}
        >
          <BiImage
            size={18}
            style={{
              marginRight: 8,
              color: disabled ? "#666" : "#212121",
            }}
          />
          <div
            style={{
              fontSize: 14,
              color: disabled ? "#666" : "#212121",
            }}
          >
            Upload profile picture
          </div>
        </UploadCustomButton>
        <input
          multiple={props.multiple}
          type="file"
          accept="image/*"
          value={props.value ?? ""}
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </>
      {/* {meta.error && meta.touched ? (
        <div className="input-error text-center">{meta.error}</div>
      ) : null} */}
    </div>
  );
};
export default Uploader;
