import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/Lokshin.png";
import { accountActions, serverActions } from "../../store/actions";
import { useAuth } from "../../utils/authContext/auth";
import { FaChevronDown } from "react-icons/fa";
import { BiCheck, BiLogOut, BiRefresh, BiUser, BiPlus } from "react-icons/bi";
import MobileMenu from "./mobileMenu";
import { urls } from "../../utils/resuableLists";
import moment from "moment";
import jwtDecode from "jwt-decode";
import NavigationLink from "./navigationLink";
import { UserType } from "utils";
const isLocal = window.location.href.includes("local");
const NavigationBar = ({ noBorder, account, server, serverActions }) => {
  const [isActive, setIsActive] = useState(false);
  const [serverModal, setServerModal] = useState(false);
  const nav = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nav]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (auth.localToken) {
        const decoded = jwtDecode(auth.localToken);
        const expireDate = new Date(decoded.exp * 1000);
        if (moment(new Date()).isAfter(expireDate)) {
          return auth.logout();
        }
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAuth = (page) => {
    const isProduction = window.location.hostname
      .toLowerCase()
      .includes("platform");
    const isLocal =
      window.location.hostname.toLowerCase().includes("localhost") ||
      window.location.hostname.toLowerCase().includes("vercel");
    if (
      !(
        window.location.pathname.includes("login") ||
        window.location.pathname.includes("register")
      )
    ) {
      if (isProduction || isLocal) {
        return nav(`/${page}`, { state: window.location.pathname });
      }
    }
    return nav(`/${page}`);
  };

  return (
    <>
      <MobileMenu />
      <div
        className="desktop-menu"
        onMouseLeave={() => {
          setIsActive(false);
          setServerModal(false);
        }}
        style={{
          background: "#fff",
          borderBottom: noBorder ? "none" : "1px solid #E9E9E9",
          width: "100%",
          position: "fixed",
          top: 0,
          zIndex: 10,
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "auto",
            height: 80,
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div
              style={{
                width: "20%",
                height: 80,
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              <a
                href={"https://lokshinrentals.co.za/"}
                style={{ fontWeight: 600, fontSize: 14, color: "#212121" }}
              >
                <img alt="logo" src={logo} width={150} href="#" />
              </a>
            </div>
            <div
              style={{
                width: "59%",
                height: 80,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ul className="menu">
                {["About", "Donation", "Properties", "Contact"].map(
                  (x, index) => (
                    <li key={index + x} className="menu-list">
                      {x !== "Properties" ? (
                        <a
                          href={
                            "https://lokshinrentals.co.za/" + x.toLowerCase()
                          }
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#212121",
                          }}
                        >
                          {x}
                        </a>
                      ) : (
                        <Link
                          to={"/" + x.toLowerCase()}
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: "#212121",
                          }}
                        >
                          {x}
                        </Link>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
            {!auth.localToken && (
              <div
                style={{
                  width: "41%",
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingLeft: "10px",
                }}
              >
                <button
                  onClick={() => onAuth("login")}
                  style={{
                    border: "2px solid #212121",
                    color: "#212121",
                    marginRight: 15,
                  }}
                >
                  Login
                </button>
                <button
                  onClick={() => onAuth("register")}
                  style={{
                    backgroundColor: "#7CB73F",
                    border: "2px solid #7CB73F",
                    color: "#fff",
                  }}
                >
                  Sign up
                </button>
              </div>
            )}
            {auth.localToken && account?.firstName && (
              <div
                style={{
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                <div
                  onClick={() => setIsActive(!isActive)}
                  className="flex cursor-pointer text-[#212121] p-2 rounded-xl items-center mr-4 border border-[#7CB73F30]"
                  style={{ boxShadow: "0px 0px 20px -3px rgb(0 0 0 / 10%)" }}
                >
                  <div
                    style={{
                      textTransform: "upperCase",
                      height: 28,
                      width: 28,
                      backgroundColor: "#7CB73F",
                      borderRadius: 20,
                      marginRight: 10,
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 13,
                        fontWeight: 800,
                        color: "#fff",
                        margin: "auto",
                      }}
                    >
                      {account?.firstName.substring(0, 2)}
                    </div>
                  </div>
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {account?.firstName}
                  </div>
                  <FaChevronDown style={{ marginTop: 2, marginLeft: 10 }} />
                </div>
                <div
                  style={{
                    transition: "0.3s",
                    backgroundColor: "#fff",
                    padding: 20,
                    boxShadow: "0px 10px 20px -3px rgb(0 0 0 / 20%)",
                    border: "2px solid #7CB73F40",
                    borderRadius: 10,
                    color: "#212121",
                    marginRight: 15,
                    alignItems: "center",
                    position: "absolute",
                    top: isActive ? 70 : -300,
                    right: 0,
                  }}
                >
                  <NavigationLink
                    onClick={() => nav("/account")}
                    label={` My Profile - ${account.type}`}
                    Icon={BiUser}
                  />
                  {account.type !== UserType.TENANT && (
                    <NavigationLink
                      onClick={() => nav("/properties/add-property")}
                      label=" Add new property"
                      Icon={BiPlus}
                    />
                  )}
                  {/* <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid rgb(234, 231, 231)",
                      paddingBottom: 15,
                      marginBottom: 15,
                    }}
                  >
                    <BsQuestionCircle
                      size={20}
                      color="#6a6a6a80"
                      style={{ marginTop: 2, marginRight: 10 }}
                    />
                    <div
                      style={{
                        textTransform: "capitalize",
                        fontSize: 13,
                        fontWeight: 600,
                        color: "#6a6a6a",
                      }}
                    >
                      Help
                    </div>
                  </div> */}
                  <NavigationLink
                    onClick={() => auth.logout()}
                    label="Logout"
                    Icon={BiLogOut}
                    isLast
                  />
                </div>
              </div>
            )}
            {isLocal && (
              <div
                style={{
                  width: 300,
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                <div
                  onClick={() => setServerModal(!serverModal)}
                  style={{
                    cursor: "pointer",
                    boxShadow: "0px 0px 20px -3px rgb(0 0 0 / 10%)",
                    padding: 8,
                    border: "1px solid #7CB73F30",
                    borderRadius: 10,
                    color: "#212121",
                    marginRight: 15,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    {server.env ? server.env : "Choose Server"}
                  </div>
                  <FaChevronDown style={{ marginTop: 2, marginLeft: 10 }} />
                </div>
                <div
                  style={{
                    transition: "0.3s",
                    backgroundColor: "#fff",
                    padding: 20,
                    boxShadow: "0px 10px 20px -3px rgb(0 0 0 / 20%)",
                    border: "2px solid #7CB73F40",
                    borderRadius: 10,
                    color: "#212121",
                    marginRight: 15,
                    alignItems: "center",
                    position: "absolute",
                    top: serverModal ? 70 : -300,
                    right: 0,
                  }}
                >
                  {urls.map((x, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        serverActions.switchServer(index);
                        window.location.reload();
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid rgb(234, 231, 231)",
                        minWidth: 200,
                        padding: "7px 0px",
                        margin: "7px 0px",
                      }}
                    >
                      <BiCheck
                        size={20}
                        color={server.env === x.env ? "#78B13E" : "#6a6a6a80"}
                        style={{ marginTop: 2, marginRight: 10 }}
                      />
                      <div
                        style={{
                          textTransform: "capitalize",
                          fontSize: 13,
                          fontWeight: 600,
                          color: server.env === x.env ? "#78B13E" : "#6a6a6a",
                        }}
                      >
                        {x.env}
                      </div>
                    </div>
                  ))}
                  <NavigationLink
                    onClick={() => serverActions.refreshServer()}
                    label=" Refresh server selection"
                    Icon={BiRefresh}
                    isLast
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  server: state.server,
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  serverActions: bindActionCreators(serverActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
