import { RENTAL_STATUS } from ".";

export const formatter = {
  toRand: (value) => {
    value = value ? value : 0;
    let amount = value.toLocaleString("en-US", {
      style: "currency",
      currency: "ZAR",
    });
    amount = amount.replace("ZAR", "R");
    return amount.replace(".00", "");
  },
  searchSplitter: (searchString, value) => {
    let cleanData = searchString?.split("?");
    cleanData = cleanData.find((x) => x.includes(value));
    cleanData = cleanData.replace(`${value}=`, "");
    cleanData = cleanData.replace(`%20`, " ");
    return cleanData;
  },
  toBoolean: (value) => (value ? value.toLowerCase() === "yes" : false),
  booleanToString: (value) => (value ? "Yes" : "No"),
  propertyUrl: (property) => {
    const address = property?.address.fullAddress.split(", ");
    if (address.length < 3) {
      let url = `${address?.[0]?.toLowerCase()}/${address?.[1]
        ?.toLowerCase()
        ?.replace(" ", "-")}/${property._id}`;
      url.replace(`/undefined/g`, "/");
      return `/properties/property/${url}`;
    }
    const township = property?.address.fullAddress.split(", ")?.[1];
    const city = property?.address.fullAddress.split(", ")?.[2];
    const url = `${city?.toLowerCase()}/${township
      ?.toLowerCase()
      ?.replace(" ", "-")}/${property._id}`;
    return `/properties/property/${url}`;
  },
  getRentalStatus: ({ status }) => {
    console.log("status", status);
    if (status.includes("occupied")) {
      return RENTAL_STATUS.OCCUPIED;
    }
    if (status.includes("later")) {
      return RENTAL_STATUS.ARCHIVED;
    }
    return RENTAL_STATUS.DELETED;
  },
  propertyAddress: (fullAddress) => {
    if (fullAddress) {
      console.log("fullAddress.split(", ")?", fullAddress.split(", "));
      const street = fullAddress.split(", ")?.[0];
      const township = fullAddress.split(", ")?.[1];
      const city = fullAddress.split(", ")?.[2];
      const postalCode = fullAddress.split(", ")?.[3];
      const country = fullAddress.split(", ")?.[4];

      return {
        street,
        township,
        city,
        postalCode,
        country,
        fullAddress: fullAddress,
      };
    }
    return {};
  },
};
