import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Oval } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { PropertyCard } from "components";
import { QUERY } from "graphql-actions";

const MyProperties = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;
  const { loading, data, refetch } = useQuery(QUERY.ROOMS.GET_USER_ROOMS);

  useEffect(() => {
    if (!loading) {
      setFilteredData(data?.getUserRooms ?? []);
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data?.getUserRooms?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data?.getUserRooms?.length / itemsPerPage));
    }
  }, [data?.getUserRooms, itemOffset, itemsPerPage, loading]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData?.length;
    setCurrentPage(event?.selected);
    setItemOffset(newOffset);
    // window.scrollTo(0, 0);
  };

  const onFilter = (value) => {
    // value = value === activeFilter ? "" : value
    setActiveFilter(value);
    const rooms = data?.getUserRooms.filter((x) => {
      return value === "Available"
        ? x.status
        : value === "Not Available"
        ? !x.status
        : x;
    });
    setFilteredData(rooms);
    setCurrentPage(0);
    setCurrentItems(rooms?.slice(0, itemsPerPage));
    setPageCount(Math.ceil(rooms?.length / itemsPerPage));
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          color: "#212121",
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        My Properties
      </div>
      <div
        style={{
          width: "100%",
          fontSize: 13,
          fontWeight: 600,
          color: "#6a6a6a",
        }}
      >
        Filter by:
      </div>
      <div style={{ display: "flex", marginBottom: 40, flexWrap: "wrap" }}>
        {["All", "Available", "Not Available"].map((x, index) => {
          const isActive = activeFilter === x;
          return (
            <div
              key={index}
              onClick={() => onFilter(x)}
              style={{
                cursor: "pointer",
                padding: "10px 15px",
                marginTop: 10,
                backgroundColor: !isActive ? "#F5F5F5" : "#212121",
                color: !isActive ? "#212121" : "#fff",
                borderRadius: 5,
                fontSize: 12,
                fontWeight: isActive ? 700 : 600,
                marginRight: 10,
              }}
            >
              {x}
            </div>
          );
        })}
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {!loading &&
          currentItems?.map((property, index) => (
            <PropertyCard
              key={index}
              property={property}
              onPropertyUpdated={() => refetch()}
              isAccountPage
            />
          ))}
        {!currentItems?.length && !loading && (
          <div
            style={{
              fontSize: 16,
              color: "#6a6a6a",
              fontWeight: "500",
              marginBottom: 20,
            }}
          >
            You have no properties.
          </div>
        )}
        <Oval
          height={30}
          width={30}
          color="#7CB73F"
          wrapperStyle={{}}
          wrapperClass=""
          visible={loading}
          ariaLabel="oval-loading"
          secondaryColor="#7CB73F"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      </div>
      <div>
        {!loading && (
          <ReactPaginate
            renderOnZeroPageCount={null}
            previousLabel={
              <BiChevronLeft
                size={20}
                style={{ marginTop: -20, marginLeft: 5 }}
              />
            }
            nextLabel={
              <BiChevronRight
                size={20}
                style={{ marginTop: -20, marginLeft: 5 }}
              />
            }
            breakLabel="..."
            pageCount={pageCount}
            onPageChange={handlePageClick}
            forcePage={currentPage}
            containerClassName={"pagination"}
            previousLinkClassName={"previous_page"}
            nextLinkClassName={"next_page"}
            activeClassName={"active_number"}
            pageClassName={"inactive_number"}
            marginPagesDisplayed={0}
            pageRangeDisplayed={5}
          />
        )}
      </div>
    </div>
  );
};

export default MyProperties;
