import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/Lokshin.png";
import { accountActions } from "../../../store/actions";
import { useAuth } from "../../../utils/authContext/auth";
import { FaChevronDown } from "react-icons/fa";
import { BiLogOut, BiPlus, BiUser } from "react-icons/bi";
import { TbMenu } from "react-icons/tb";
import MobileMenuSlider from "../mobileMenuSlider";
import NavigationLink from "../navigationLink";
import clsx from "clsx";

const MobileMenu = ({ noBorder, account }) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const nav = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nav]);

  return (
    <div
      className={clsx(
        "mobile-menu w-full fixed top-0 z-[999] bg-white border-none",
        {
          "border border-[#E9E9E9]": noBorder,
        }
      )}
      onMouseLeave={() => setIsActive(false)}
    >
      <MobileMenuSlider isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <div className="max-w-[1200px] m-auto h-20 justify-center content-center">
        <div className="flex">
          {!auth.localToken ? (
            <div className="w-1/2 h-20 flex items-center px-[10px]">
              <a
                href={"https://lokshinrentals.co.za/"}
                style={{ fontWeight: 600, fontSize: 14, color: "#212121" }}
              >
                <img alt="logo" src={logo} width={150} href="#" />
              </a>
            </div>
          ) : (
            <div className="w-[20%] h-20 flex items-center px-[10px]">
              <TbMenu size={35} onClick={() => setIsOpen(!isOpen)} />
            </div>
          )}

          {!auth.localToken && (
            <div className="w-1/2 h-20 flex items-center ml-auto justify-end px-[10px]">
              <TbMenu size={35} onClick={() => setIsOpen(!isOpen)} />
            </div>
          )}
          {auth.localToken && account?.firstName ? (
            <div className="h-20 w-[80%] flex items-center pl-[10px] relative">
              <div
                onClick={() => setIsActive(!isActive)}
                className="text-[#212121] cursor-pointer p-2 rounded-xl flex items-center ml-auto mr-4 border border-[#7CB73F30]"
                style={{ boxShadow: "0px 0px 20px -3px rgb(0 0 0 / 10%)" }}
              >
                <div className="uppercase h-7 w-7 bg-[#7CB73F] mr-[10px] flex rounded-[20px]">
                  <div className="m-auto text-white font-extrabold text-[13px]">
                    {account?.firstName.substring(0, 2)}
                  </div>
                </div>
                <div className="capitalize text-sm font-semibold">
                  {account?.firstName}
                </div>
                <FaChevronDown style={{ marginTop: 2, marginLeft: 10 }} />
              </div>
              <div
                className="bg-white text-[#212121] p-5 rounded-xl right-0 absolute items-center mr-4 border-2 border-[#7CB73F40]"
                style={{
                  transition: "0.3s",
                  boxShadow: "0px 10px 20px -3px rgb(0 0 0 / 20%)",
                  top: isActive ? 70 : -300,
                }}
              >
                <NavigationLink
                  onClick={() => nav("/account")}
                  label={`My Profile - ${account.type}`}
                  Icon={BiUser}
                />
                <NavigationLink
                  onClick={() => nav("/properties/add-property")}
                  label="Add new property"
                  Icon={BiPlus}
                />
                {/* <NavigationLink
                  onClick={() => auth.logout()}
                  label="My Info"
                  Icon={TbEdit}
                />
                <NavigationLink
                  onClick={() =>
                    window.open("https://lokshinrentals.co.za/contact", "_self")
                  }
                  label="Help"
                  Icon={BsQuestionCircle}
                /> */}
                <NavigationLink
                  onClick={() => auth.logout()}
                  label="Logout"
                  Icon={BiLogOut}
                  isLast
                />
              </div>
            </div>
          ) : (
            auth.localToken &&
            !account?.firstName && (
              <div
                style={{
                  width: "20,664%",
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                  position: "relative",
                }}
              >
                <button
                  onClick={() => auth.logout()}
                  style={{
                    border: "2px solid #212121",
                    color: "#212121",
                    marginRight: 15,
                  }}
                >
                  Logout
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(accountActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
